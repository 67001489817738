import React from 'react';
import { Box, Container, Heading, Image, Text, Stack } from '@chakra-ui/react';

const privacyPolicyImage = "https://www.hitechnectar.com/wp-content/uploads/2018/04/150572190320170918.jpg"

const PrivacyPolicy = () => {
  const phoneNumber = "9142429858";
  const formattedNumber = `tel:${phoneNumber}`;
  const emailAddress = "info@sonamagrotech.com";
  const mailtoLink = `mailto:${emailAddress}`;
  const handlePhoneClick = () => {
    window.location.href = formattedNumber;
  };
  return (
    <Box bg={"linear-gradient(180deg, #f3ffe1 0%, #fff 100%)"}>
    <Container maxW="container.lg" p={5}>
      <Box textAlign="center" mb={5}>
        <Image src={privacyPolicyImage} alt="Privacy Policy"  borderRadius="md"
          objectFit="contain"
          width="100%"
          height="250px" />
      </Box>
      <Heading as="h1" mb={5}>
        Privacy Policy
      </Heading>
      <Stack spacing={5}>
        {/* Introduction to the privacy policy */}
        <Text>
        This privacy policy sets out how Sonam Agrotech Pvt Ltd. uses and protects any information that you give Sonam Agrotech Pvt Ltd. when you use this website.
        </Text>
        <Text>Sonam Agrotech Pvt Ltd. is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, and then you can be assured that it will only be used in accordance with this privacy statement.
 </Text>
 <Text>Sonam Agrotech Pvt Ltd. may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This policy is effective from 1-dec-2024.</Text>

        {/* Details about what information is collected */}
        <Text fontWeight="bold">What we collect</Text>
        <Text>
          We may collect the following information:
          <ul>
            <li>Name and job title</li>
            <li>Contact information including email address</li>
            <li>Demographic information such as postcode, preferences, and interests</li>
            <li>Other information relevant to customer surveys and/or offers</li>
          </ul>
        </Text>

        {/* Explanation of how the collected information is used */}
        <Text fontWeight="bold">What we do with the information we gather</Text>
        <Text>
          We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:
          <ul>
            <li>Internal record keeping.</li>
            <li>We may use the information to improve our products and services.</li>
            <li>We may periodically send promotional emails about new products, special offers, or other information which we think you may find interesting using the email address you provided.</li>
            <li>From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customise the website according to your interests.</li>
          </ul>
        </Text>

        <Text fontWeight="bold">Security</Text>
        <Text>
          We are committed to ensuring that your information is secure. To prevent unauthorized access or disclosure, we have put in place suitable physical, electronic, and managerial procedures to safeguard and secure the information we collect online.
        </Text>

        <Text fontWeight="bold">How we use cookies</Text>
        <Text>
        A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyses web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.
        </Text>
        <Text>We use traffic log cookies to identify which pages are being used. This helps us analyses data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.</Text>
        <Text>Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.</Text>
        <Text>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.</Text>

        <Text fontWeight="bold">Links to other websites</Text>
        <Text>
          Our website may contain links to other websites of interest. However, once you have used these links to leave our site, we do not have control over those other sites. Be sure to check the privacy policy of those sites to ensure the safety of your personal information.
        </Text>

        <Text fontWeight="bold">Controlling your personal information</Text>
        <Text>
        You may choose to restrict the collection or use of your personal information in the following ways:
        </Text>
        <ul>
          <li>whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes</li>
          <li>if you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at <Text as={"b"}> <a href={mailtoLink}>{emailAddress}</a></Text></li>
        </ul>
<Text>We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.</Text>
<Text>You may request details of personal information which we hold about you under the Data Protection Act 1998. A small fee will be payable. If you would like a copy of the information held on you please write to Sonam Agrotech Pvt Ltd. FLAT NO. 602, KUMAR RANJAN ENCLAVE, NEAR BAHADURPUR FLYOVER, KANKARBAGH, Patna, Bihar, 800020</Text>
<Text>If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect.</Text>
      
        <Text fontWeight="bold">Contacting Us</Text>
        <Text>
          If there are any questions regarding this privacy policy, you may contact us at:
          <ul>
            <li>Address: FLAT NO. 602, KUMAR RANJAN ENCLAVE, NEAR BAHADURPUR FLYOVER, KANKARBAGH, Patna, Bihar, 800020</li>
            <a href="#" onClick={handlePhoneClick}> <li>Phone: <Text as={"b"}>9142429858</Text></li></a>
            <li>Email: <Text as={"b"}> <a href={mailtoLink}>{emailAddress}</a></Text></li>
          </ul>
        </Text>
      </Stack>
    </Container>
    </Box>
  );
};

export default PrivacyPolicy;
