import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchCategory, fetchCategoryById } from "./CategoryAPI";

const initialState = {
  value: 0,
  status: "idle",
  categoryData: [],
  admincategoryData: [],
  categoryDataById: null,
};

export const fetchCategoryAsync = createAsyncThunk(
  "category/fetchCategory",
  async () => {
    const response = await fetchCategory();

    return response.data;
  }
);
export const fetchCategoryByIdAsync = createAsyncThunk(
  "category/fetchCategoryById",
  async (categoryId) => {
    const response = await fetchCategoryById(categoryId);

    return response.data;
  }
);

export const CategorySlice = createSlice({
  name: "category",
  initialState,

  reducers: {
    increment: (state) => {
      state.value += 1;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCategoryAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.categoryData = action.payload;
      })
      .addCase(fetchCategoryByIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCategoryByIdAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.categoryDataById = action.payload;
      })
  },
});
// export const { increment } = UserSlice.actions;
export const selectCategory = (state) => state.category.categoryData; 
export const selectCategoryById = (state) => state.category.categoryDataById; 


export default CategorySlice.reducer;
