import {
  Box,
  Container,
  Heading,
  Image,
  ListItem,
  OrderedList,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';

const ReturnPolicy = () => {
  const emailAddress = "info@sonamagrotech.com";
  const mailtoLink = `mailto:${emailAddress}`;
  return (
    <Box bg={"linear-gradient(180deg, #f3ffe1 0%, #fff 100%)"}>
    <Container maxW="container.xl" p={6} >
      <Box mb={6}>
        <Image
          src="https://www.clickpost.ai/hubfs/featured%20images/Updated%20blog%20banner%20images%20Mar%2022/refund-and-return-policy-generator.webp#keepProtocol"
          alt="Return/Refund Policy Banner"
          borderRadius="md"
          objectFit="contain"
          width="100%"
          height="250px"
        />
      </Box>

      {/* Page Content */}
      <Stack spacing={5}>
        <Heading as="h1" size="xl">Return / Refund Policy</Heading>
 <UnorderedList>
  <ListItem>A refund will be considered only if the request is made within 7 days of placing an order. (If the product is damaged, Duplicate or quantity varies).</ListItem>
  <ListItem>The refund will be done within 7 days.</ListItem>
  <ListItem>The return will be processed by 4-5 working days.</ListItem>
  <ListItem>The return will be processed only if:</ListItem>
  <OrderedList>
    <ListItem>It is determined that the product was not damaged while in your possession</ListItem>
    <ListItem>The product is not different from what was shipped to you</ListItem>
    <ListItem>The product is returned in its original condition</ListItem>
    <ListItem>Customer must take an unboxing video. </ListItem>
  </OrderedList>
  <ListItem>In case of receipt of damaged or defective items please report the same to our Customer Service team. The request will, however, be entertained once the merchant has checked and determined the same at his own end. This should be reported within 48 hours of receipt of the products. </ListItem>
  <ListItem>In case you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within 24 hours of receiving the product. The Customer Service Team after looking into your complaint will take an appropriate decision.</ListItem>
  <ListItem><Text as='b'>NOTE:</Text> If you have done an online payment and you are canceling the order,  the payment gateway charge 3% and courier charge (Forward  + Return ) will be deducted from your refund amount. If you have ordered and product is in transit (shipped) we will charge 3% and courier charge (Forward  + Return ) from you.</ListItem>
  <ListItem>Its your responsibility to take out print of the manifest that Sonam Agrotech pvt ltd. provides, while returning the product.</ListItem>
 </UnorderedList>
        <Text fontSize="md">
        To request a refund, simply mail us on <Text as={"b"}> <a href={mailtoLink}>{emailAddress}</a></Text>  with your purchase details within seven (7) days of your purchase. Please include your order number (sent to you via email/message after ordering) and optionally tell us why you’re requesting a refund – we take customer feedback very seriously and use it to constantly improve our products and quality of service. Refunds are being processed after customer support team validate and analyze issue, but it will only show in your bank account only when the product comes back to hub. Once the refund is processed we will intimate you through EMAIL and SMS with the refund transaction details.
To complete your return, we require a receipt or proof of purchase.
        </Text>


        <Text fontSize="md">
        Please do not send your purchase back to the manufacturer.
        </Text>

        <Text fontSize="md" as={"b"}>
        The refund is only possible if the product is unused, undamaged, and is with the original packaging and with the receipt and the invoice. Only when the product will reach premises we will refund you your amount as decided.
        </Text>

        <Text fontSize="md">
      <Text as={"b"}>NOTE :</Text> For a refund, the name of the customer must be the same as registered(Sonam Agrotech pvt ltd.) account name and bank account name, Customer must send a cheque photo or passbook photo for a refund with Adhaar Card or PAN card.
        </Text>


       

        <Text fontSize="md">
          For queries, contact us at <Text as={"b"}><a href={mailtoLink}>{emailAddress}</a>.</Text>
        </Text>
        <Text fontSize="md">
        For agricultural and gardening use only. The products are beyond our control hence we are not responsible for any damage. 
Please note the dose that is given are just for reference and we do not claim or take any responsibility for the exact dose and we do not provide any schedule for your crops, plants, or trees
        </Text>
        <Text as={"b"}>The seeds germination depends on the soil, atmosphere, fertilizers, watering etc. Sonam Agrotech pvt ltd. never claims about seeds germination and harvesting and so refund is not possible in seeds.</Text>
        <Text as={"b"}>No Warranty on Live Plants as Growing, flowering and fruiting are subject to So many climatic condition like Air, Kind of soil, water, humidity and temperature beyond our control.</Text>
        <Text>Kindly note that refund will be made only after proper investigation of the whole scenario by our special team.</Text>
        <Text>Sonam Agrotech pvt ltd. reserve the rights to change or update the terms and conditions at any time with or without any prior notice. To make sure you are always updated of the terms and conditions, please review this website periodically. </Text>
      
      </Stack>
    </Container></Box>
  );
};

export default ReturnPolicy;
