import {Grid,GridItem,Container,Text} from "@chakra-ui/react";
import React from "react";
import { AddAddressForm } from "../Form/AddAddressForm";
import SelectAddress from "./SelecteAddress";
import { useSelector } from "react-redux";
import { userAddressSelector } from "../../features/User/UserSlice";

const DelieveryAddress = ({ id }) => {
  const diliveryAddress = useSelector(userAddressSelector);
  if (diliveryAddress?.status === 1) {
   var address = diliveryAddress.data;
  }

  return (
    <Grid
      templateColumns={["1fr","1fr","1fr","1fr","repeat(2, 1fr)"]}
      gap={[0,0,0,0,6]}
      background="#FFFFFF"
      boxShadow="lg"
      borderRadius={"14px"}
      paddingBottom={"20px"}
    >
      <GridItem colSpan={1} ml={[0,0,0,0,"10%"]} mt={[0,0,0,0,"20px"]} p={4}>
        <Text color={"#707070"} fontSize="16px" fontWeight={"700"}>
          DELIVERY ADDRESS
        </Text>          

        {/* <InputGroup
          w={"275px"}
          // h="50px"
          borderRadius={"7px"}
          border="1px solid #151B39"
          mt={"20px"}
        > */}
          <AddAddressForm user_id={id} />
        {/* </InputGroup> */}
      </GridItem>

      <GridItem colSpan={1} >
        {" "}
        <Container maxW="container.lg" py="8" >
          {address?.map((boxData) => (
            
              <SelectAddress
                key={boxData._id}
                data={boxData}
                user_id={id}
                
              />
          ))}
        </Container>
      </GridItem>
    </Grid>
  );
};

export default DelieveryAddress;
