import React from "react";
import { Box, Button, Flex, Input, Select, Text } from "@chakra-ui/react";
import "./Form.css";
import {
  nameIcon,
  emailIcon,
  phoneIcon,
  stateIcon,
  PincodeIcon,
  heartIcon,
} from "../constant/Icons";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createContactUsAsync } from "../../features/ContactUs/ContactUsSlice";
import { states } from "../../utils";

const contact_form_style = {
  inputs: {
  height:"50px",
    borderRadius: "8px",
    border: "1px solid #A0A0A0",
  },
  inputsmall: {
    height: "50px",
    borderRadius: "8px",
    border: "1px solid #A0A0A0",
  },
  // btn: {
  //   borderRadius: "8px",
  //   background: "#52771A",
  //   color: "#FFF",
  //   fontFamily: "Inter",
  //   fontSize: "16px",
  //   fontStyle: "normal",
  //   fontWeight: "500",
  //   marginTop: "40px",
  //   marginLeft: "370px",
  // },
};

const ContactForm = () => {
   const { register, handleSubmit } = useForm();
    const dispatch = useDispatch();
    const onSubmit = (data) => {
      data.reletedTo = " main contact page";
      data.name = "?? plese contact soon as possible";
      dispatch(createContactUsAsync(data));
    };
  return (
    <Box margin={["0px","5px","10px","30px","50px"]} >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display={"flex"} gap="20px" marginTop={["20px"]}  >
          <Box marginTop={"-2px"}>{heartIcon()}</Box>
          <Text id="form_heading">Hello, Let’s get in touch</Text>
        </Box>

        <Box className="form-group" >
          {nameIcon()}
          <Text className="form-label">Name</Text>
        </Box>
        <Input w={["100%","100%","100%","480px","520px"]} 
          id="Username"
          {...register("Username", {
            required: "Name must be required",
          })}
          style={contact_form_style.inputs}
        />

        <Box className="form-group">
          {emailIcon()}
          <Text className="form-label">Email ID</Text>
        </Box>
        <Input w={["100%","100%","100%","480px","520px"]} 
          id="Useremail"
          {...register("Useremail")}
          style={contact_form_style.inputs}
        />

        <Box className="form-group">
          {phoneIcon()}
          <Text className="form-label">Phone no.</Text>
        </Box>
        <Input w={["100%","100%","100%","480px","520px"]} 
          id="UserphoneNo"
          {...register("UserphoneNo", {
            required: "phoneNo must be required",
          })}
          style={contact_form_style.inputs}
        />
        <Box display={"flex"} flexDirection={["column","column","column","row","row"]} gap={["0px","0px","0px","80px","120px"]}>
          <Box >
            <Box className="form-group">
              {stateIcon()}
              <Text className="form-label">State</Text>
            </Box>
            <Select w={["100%","100%","100%","200px","200px"]}
              id="state"
              {...register("state", {
                required: "state must be required",
              })}
              style={contact_form_style.inputsmall}
            >
              {states.map((el) => (
                <option key={el} value={el}>
                  {el}
                </option>
              ))}
            </Select>
          </Box>
          <Box>
            <Box className="form-group">
              {PincodeIcon()}
              <Text className="form-label">Pin Code</Text>
            </Box>
            <Input w={["100%","100%","100%","200px","200px"]}
              id="pincode"
              {...register("pincode", {
                required: "pincode must be required",
              })}
              style={contact_form_style.inputsmall}
            />
          </Box>
        </Box>
<Flex justifyContent={"flex-end"}>
<Button type="submit" borderRadius={"8px"} bg={"#52771A"} color={"#FFF"} fontSize={"16px"} mt={["10px","40px"]}>
          Send Message
        </Button>
</Flex>
       
      </form>
    </Box>
  );
};

export default ContactForm;
