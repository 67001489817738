import React, { useState } from "react";
import {
  Box,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

import Slider from "react-slick";

export default function CategoryCarousel({ categorydata, allproduct }) {
  const [slider, setSlider] = useState(null);

    const [selectedItem, setSelectedItem] = useState(0);

    const handleItemClick = (index) => {
      setSelectedItem(index);
    };
  const maxSlidesToShow = Math.min(categorydata.length, 7);
  const slidesToShow = useBreakpointValue({  base: 1,
    sm:1,
    md: Math.min(2, maxSlidesToShow),
    lg: Math.min(4, maxSlidesToShow),
    xl: Math.min(7, maxSlidesToShow),
  });
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, background: "green",borderRadius:"50%" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, background: "green",borderRadius:"50%" }}
        onClick={onClick}
      />
    );
  }
  var settings = {
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 3000,
    arrows: true,
    speed: 500,
    slidesToShow: slidesToShow, 
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };
  return (
    <Box paddingLeft={"40px"} paddingRight={"40px"} position={"relative"} width={"full"} overflow={"hidden"}>
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <Slider {...settings} ref={(slider) => setSlider(slider)}>
        <Box
          w={"171px"}
          h="254px"
          borderRadius={"14px"}
          onClick={() => handleItemClick(0)}
          backgroundColor={selectedItem === 0 ? "#52771A" : null}
          color={selectedItem === 0 ? "#f3ffe1" : null}
        >
          <Box
            w="150px"
            h="150px"
            borderRadius={"50%"}
            // border="1px"
            margin={"auto"}
            mt="20px"
            backgroundColor={"#FFFFFF"}
            display="flex"
          >
            <Image
              borderRadius={"50%"}
              src={allproduct?.categoryImage}
              w="110px"
              h="110px"
              p="20px"
              margin={"auto"}
            />
          </Box>

          <Text
            textAlign="center"
            fontSize={"16px"}
            fontWeight="700"
            mt={"20px"}
          >
            {"All Products"}
          </Text>
        </Box>
        {categorydata?.map((el, index) => (
          <Box key={index}
            w={"171px"}
            h="254px"
            borderRadius={"14px"}
            transition={"background-color 0.3s, color 0.3s"}
            _hover={{ backgroundColor: "#d7faa3"}}
            color={selectedItem === (index + 1 )? "#f3ffe1" : null}
            backgroundColor={selectedItem === (index + 1) ? "#52771A" : null}
            onClick={() => handleItemClick(index + 1)}
          >
            <Box
              w="150px"
              h="150px"
              borderRadius={"50%"}
              // border="1px"
              margin={"auto"}
              mt="20px"
              backgroundColor={"#FFFFFF"}
              display="flex"
            >
              <Image
                borderRadius={"50%"}
                src={el.subcategoryImage}
                w="110px"
                h="110px"
                p="20px"
                margin={"auto"}
              />
            </Box>

            <Text
              textAlign="center"
              fontSize={"16px"}
              fontWeight="700"
              mt={"20px"}
            >
              {el.subcategoryName}
            </Text>
          </Box>
        ))}
      </Slider>
    </Box>
  );
}
