import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef } from "react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import logo from "../../images/logo.jpg";
import homeIcon from "../../images/home.svg"
import aboutIcon from "../../images/About Us.svg"
import govtIcon from "../../images/Govt Schemes.svg"
import productIcon from "../../images/cubes.svg"
import serviceIcon from "../../images/Service.svg"
import contactIcon from "../../images/contactFill.svg"
export default function SideNavbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  return (
    <>
      <Button ref={btnRef} onClick={onOpen} variant='ghost'  w={"50%"}>
        <HamburgerIcon/>
      </Button>
      <Drawer 
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      
      >
        <DrawerOverlay />
        <DrawerContent bg={"#f3ffe1"}>
          <DrawerCloseButton />
          <DrawerHeader>
            <Box id="logo_name_box">
              <Image src={logo} alt="logo" width={"55px"} height="78px"></Image>
              <Text id="name">SONAM</Text>
            </Box>
          </DrawerHeader>

          <DrawerBody m={"auto"} mt={"20px"}>
            <Box display={"flex"} flexDirection="column" gap={"30px"}>
            <Link to={"/"}>
                  <Flex  gap={"10px"}  onClick={onClose}>
                  <Image src={homeIcon} w={"20px"} h={"20px"}/>
                    <Text color={"#404040"} fontSize={"14px"} fontWeight={"700"}>HOME</Text>
                  </Flex>
                </Link>
                <Link to={"/products"}>
                  <Flex  gap={"10px"}  onClick={onClose}>
                  <Image src={productIcon} w={"20px"} h={"20px"}/>
                    <Text color={"#404040"} fontSize={"14px"} fontWeight={"700"}>PRODUCTS</Text>
                  </Flex>
                </Link>
                <Link to={"/service"}>
                  <Flex  gap={"10px"}  onClick={onClose}>
                  <Image src={serviceIcon} w={"20px"} h={"20px"}/>
                    <Text color={"#404040"} fontSize={"14px"} fontWeight={"700"}>SERVICES</Text>
                  </Flex>
                </Link>
                <Link to={"/about"}>
                  <Flex  gap={"10px"}  onClick={onClose}>
                  <Image src={aboutIcon} w={"20px"} h={"20px"}/>
                    <Text color={"#404040"} fontSize={"14px"} fontWeight={"700"}>ABOUT US</Text>
                  </Flex>
                </Link>
                <Link to={"/govtscheme"}>
                  <Flex  gap={"10px"}  onClick={onClose}>
                  <Image src={govtIcon} w={"20px"} h={"20px"}/>
                    <Text color={"#404040"} fontSize={"14px"} fontWeight={"700"}>GOVT SCHEMES</Text>
                  </Flex>
                </Link>
                <Link to={"/contact"} >
                  <Flex  gap={"10px"}  onClick={onClose}>
                  <Image src={contactIcon} w={"20px"} h={"20px"}/>
                    <Text color={"#404040"} fontSize={"14px"} fontWeight={"700"}>CONTACT US</Text>
                  </Flex>
                </Link>
                
             
            </Box>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
