import {Tag} from "@chakra-ui/react";
import React from "react";

const Pills = ({ name }) => {
  return (
    <>
  <Tag px={["2","10"]} variant='solid' bg='#FED843'  color="#52771A" fontFamily={"Inter"} fontWeight={"700"} h={["28px"]}>
     {name}
  </Tag>


    </>
    
  );
};

export default Pills;
