import { Box, Skeleton, SkeletonText, Stack } from "@chakra-ui/react";
import React from "react";

const schemecardStyle = {
  name: {
    color: "#404040",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  btn: {
    borderRadius: "14px",
    background: "#52771A",
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
};
const GovtPageSkeleton = ({ name, state, content, image }) => {

  return (
      <Box  display="grid" gridTemplateColumns={["1fr","1fr","1fr","1fr","repeat(2,1fr)"]} gap={["30px","50px" ]}  padding={["10px","40px"]}>
        {new Array(8).fill('loading').map((el,i)=>(
    <Box key={i} style={schemecardStyle.main_box}   width="100%" >
      <Box  bg= "#FFF" border={"1px solid #d2e3bf"}   borderRadius= "24px" padding={["20px","40px"]}>   
      <Box display={"flex"} justifyContent="space-between">
      <Skeleton h={7} w={"40%"} mb={5} ></Skeleton>
       <Skeleton h={7} w={"20%"} borderRadius= "10px"/>
      </Box>

      <Box  mt={["20px","42px"]} mb={["20px","42px"]}  >
        <Skeleton w={["100%","100%","348px","448px","548px"]} h={["148px","248px","276px","276px","276px"]} borderRadius={"14px"} m="auto" />
      </Box>
      <SkeletonText
                mt="4"
                noOfLines={7}
                spacing="4"
                skeletonHeight="2"
                
              />
      <Stack />

      <Box display="flex" justifyContent={"end"} mr={2} >
      <Skeleton
                width={"30%"}
                borderRadius="7px"
                h={10}
                mt={20}
              ></Skeleton>
      </Box>
          </Box>
    </Box>
 ))}
    </Box>
  );
};

export default GovtPageSkeleton;

