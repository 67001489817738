import axios from "axios";

// A mock function to mimic making an async request for data
export function fetchUserProfile() {
  return new Promise(async (resolve, reject) => {
      const token = localStorage.getItem('token') || "";
     
      const response = await axios.post(process.env.REACT_APP_BASE_API + "/users/getProfile", {token : token});
      const data = response.data;
      resolve({ data });
  
    
  });
}
export function checkPhoneNo(phoneNo) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+ "/users/checkphoneno",
      { phoneNo: phoneNo }
    );
    const data = response.data;

    resolve({ data });
  });
}
export function registerUser(userData) {
  console.log(userData,"AKAKAKA")
  return new Promise(async (resolve) => {
    const response = await axios.post(process.env.REACT_APP_BASE_API+"/users/register", {
      userData: userData,
    });
    const data = response.data;

    resolve({ data });
  });
}
export function forgetPassword(userData) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/users/forgetPassword",
      {
        userData: userData,
      }
    );
    const data = response.data;

    resolve({ data });
  });
}
export function loginUser(userData) {
  
  return new Promise(async (resolve) => {
    const response = await axios.post(process.env.REACT_APP_BASE_API+"/users/login", {
      userData: userData,
    }); 
    if(response?.data?.status === 1){
      localStorage.setItem('token', response?.data?.data?.token);
           
    }
    const data = response.data;

    resolve({ data });
  });
}
export function addDeliveryAddress(id, address) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/users/addDeliveryAddress",
      {
        user_id: id,
        address: address,
      }
    );
    const data = response.data;

    resolve({ data });
  });
}
export function getDeliveryAddress(id) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/users/getDeliveryAddress",
      {
        user_id: id,
      }
    );
    const data = response.data;

    resolve({ data });
  });
}
export function updateDeliveryAddress(user_id, address_id, updatedAddress) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/users/updateDeliveryAddress",
      {
        user_id,
        address_id,
        updatedAddress,
      }
    );
    const data = response.data;

    resolve({ data });
  });
}
export function updateUser({UserData,userId}) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/users/updateUser",
      {
      fullName: UserData.fullName,
      email: UserData.email,
      // mobileNo: req.body.userData.mobileNo,
      // city: req.body.userData.city,
      state: UserData.state,
      pincode:UserData.pincode
       ,
        userId:userId
      }
    );
    const data = response.data;
    resolve({ data });
  });
}
export function updateSelectedAddress(user_id, address_id) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/selectedaddress/addselectAddress",
      {
        user_id,
        address_id,
        
      }
    );
    const data = response.data;

    resolve({ data });
  });
}
export function deleteDeliveryAddress(user_id, address_id) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/users/deleteDeliveryAddress",
      {
        user_id,
        address_id,
      }
    );
    const data = response.data;

    resolve({ data });
  });
}
export function fetchUser(id) {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      process.env.REACT_APP_BASE_API+ `/users/getuser/${id}`
    );
    const data = response.data;
    resolve({ data });
  });
}
export function fetchSelectedAddress(id) {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      process.env.REACT_APP_BASE_API+`/selectedaddress/getselectAddress/${id}`
    );
    const data =  response.data;
       resolve({ data });
  });
}
export function verifyOtp({mobile,otp}) {
  return new Promise(async (resolve) => {
    const response = await axios.post(process.env.REACT_APP_BASE_API+"/users/verifyOtp", {
      otp: otp,
      mobile: mobile,
    });
    const data = response.data;

    resolve({ data });
  });
}
export function sendOtp(mobile) {
  return new Promise(async (resolve) => {
    const response = await axios.post(process.env.REACT_APP_BASE_API+"/users/sendOtp", {
     
      mobile: mobile,
    });
    const data = response.data;

    resolve({ data });
  });
}



