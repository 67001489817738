import { useState } from "react";
import { Image, InputGroup, InputLeftAddon, InputRightElement, useDisclosure } from "@chakra-ui/react";
import "react-phone-input-2/lib/style.css";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { toast, Toaster } from "react-hot-toast";
import "./LoginModal.css";
import indianFlagImage from "../../images/IndianFlag.png";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Text,
  Input,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import ModalBox from "./ModalBox";
import { useDispatch } from "react-redux";
import {
  fetchUserProfileAsync,
  loginUserAsync,
} from "../../features/User/UserSlice";
import NewPasswordModal from "./NewPasswordModal";
function LoginModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const [mobileNo, SetMobileNo] = useState("");
  const handleClick = () => setShow(!show);
  const onSubmit = (data) => {
    console.log(data, "..........");
    data.mobileNo = mobileNo;

    if (mobileNo.length >= 10) {
      dispatch(loginUserAsync(data))
        .then((response) => {
          if (response?.payload?.status === 1) {
            toast.success(response?.payload?.message);
            setTimeout(() => {
              dispatch(fetchUserProfileAsync());
            }, 1000);
          } else {
            toast.error(response?.payload?.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      toast.error("Please enter a valid phone number.");
    }
  };

  return (
    <>
      <Button
        onClick={onOpen}
        id="login_btn"
        variant="outline"
        w={["50px", "50px", "70px", "111px"]}
        h={["30px", "40px"]}
        fontSize={["10px", "14px", "16px"]}
        mr={["10px", "10px", "10px", "10px", "0px"]}
      >
        LOGIN
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          style={{
            backdropFilter: "blur(2px)", // Apply the backdrop blur effect
          }}
        />
        <ModalContent
          width={["90%", "700px"]}
          height={["auto", "550px"]}
          borderRadius="28px"
          background="#fff"
          padding={[2, 10]}
        >
          <ModalCloseButton
            backgroundColor="#8E8E8E"
            borderRadius="50%"
            color="white"
          />
          <ModalBody>
            <Text
              color="#404040"
              fontFamily="Inter"
              fontSize={["20px", "32px"]}
              fontStyle="normal"
              fontWeight="700"
              lineHeight="1.5"
              mt="4"
            >
              Login
            </Text>

            <Text fontSize={["10px", "16px"]}>
              Please enter the given credentials
            </Text>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Toaster toastOptions={{ duration: 3000 }} />
              <Text
                color="#404040"
                fontFamily="Inter"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                mt="4"
              >
                Mobile No
              </Text>
              <InputGroup>
                <InputLeftAddon>
                  <Image
                    src={indianFlagImage}
                    borderRadius="full"
                    boxSize="25px"
                    alt="+91"
                  />
                </InputLeftAddon>
                <Input
                  type="number"
                  placeholder="phone number"
                  value={mobileNo}
                  onChange={(event) => SetMobileNo(event.target.value)}
                />
              </InputGroup>
              {/* 
              <PhoneInput
                country={"in"}
                value={mobileNo}
                onChange={SetMobileNo}
                 inputStyle={{width:"100%"}}
              /> */}

              <Text
                color="#404040"
                fontFamily="Inter"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                mt="4"
              >
                Password
              </Text>
              <InputGroup size="md">
                <Input
                  id="password"
                  {...register("password")}
                  focusBorderColor="#0336FF"
                  w={"100%"}
                  pr="4.5rem"
                  type={show ? "text" : "password"}
                  placeholder="Enter password"
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClick}>
                    {show ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {/* <Input
                id="password"
                type="password"
                {...register("password")}
                mt="2"
                focusBorderColor="#0336FF"
                w={"100%"}
              /> */}
              <NewPasswordModal />

              <Button
                type="submit"
                borderRadius={"14px"}
                bg={"#52771a"}
                w={["300px", "320px"]}
                h={"40px"}
                mt={"30px"}
                color={"#f3ffe1"}
                fontSize={"16px"}
                fontWeight={500}
              >
                Login
              </Button>

              <ModalBox />
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default LoginModal;
