import {
  Box,
  Input,
  Text,
  InputGroup,
  InputRightElement,
  Tag,
  Divider,
  useBreakpointValue
} from "@chakra-ui/react";
import React from "react";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import OrderCard from "./OrderCard";


const MyOrders = () => {
  const isMobile = useBreakpointValue({base: true,lg:false})
  return (
    <Box
      h="auto"
      background="#FFFFFF"
      boxShadow="lg"
      borderRadius={"14px"}
      p="20px"
    >
      <Text
        color={"#707070"}
        fontFamily="Inter"
        fontSize={"16px"}
        fontWeight="700"
      >
        MY ORDERS
      </Text>
      <InputGroup mt={"2%"}>
        <Input
          placeholder="Search your orders here"
          w={["100%","100%","100%","600px","683px"]}
          h={"42px"}
          color="#A0A0A0"
          fontSize={"13px"}
          borderRadius="50px"
          border={"1px solid #8E8E8E"}
          backgroundColor="#FFFFFF"
        />
        <InputRightElement>
          <SearchIcon w={"24px"} h="26px" color={"#8E8E8E"} mr="20px" />
        </InputRightElement>
      </InputGroup>

      <Box
        mt={[0,"2%"]}
        p="20px 20px 0px 20px"
        w={["100%","100%","100%","600px","683px"]}
        borderRadius={"7px"}
        backgroundColor="#F5F5F5"
      >
        {!isMobile && (

      
        <Box display={"flex"} justifyContent="space-between">
          <Box display={"flex"} gap="30px">
            <Tag
              display={"flex"}
              w={"150px"}
              h={["20px","30px"]}
              borderRadius={"50px"}
              background="#FFFFFF"
              gap="5px"
              boxShadow={"lg"}
            >
              <Text color={"#000000"}  fontSize={["10px","13px"]}> Order</Text>
              <Text
                color={"#0336FF"}
                fontFamily="Inter"
                fontSize={["10px","13px"]}
                fontWeight="400"
              >
                #R03475689
              </Text>
            </Tag>

            <Text color={"#404040"} fontSize="10px" fontWeight={"400"} mt="2%">
              Order Placed: Fri 08th Sept 23
            </Text>
          </Box>

          <Tag
            backgroundColor="#F6B445"
            borderRadius={"50px"}
            h={"30px"}
            color="#FFFFFF"
            fontFamily={"Inter"}
            fontSize={["10px","13px"]}
            fontWeight={"400"}
            variant="solid"
            colorScheme="teal"
          >
            Status : In - Transit
          </Tag>
        </Box>
  )}
        <Box mt={["0px","20px"]}>
          <OrderCard />
          <OrderCard />
        </Box>
{!isMobile && (
        <Box>
          <Divider h={"1px"} background="#FFF" />
          <Box
            display={"flex"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Box display={"flex"} gap="20px" alignItems={"center"}>
              <CloseIcon w="10px" h={"10px"} color="#707070" ml={"10px"} />
              <Text
                color="#707070"
                fontFamily={"Inter"}
                fontSize="10px"
                fontWeight={"700"}
              >
                CANCEL ORDER
              </Text>
              <Divider
                orientation="vertical"
                w={"1px"}
                h={"39px"}
                background="#FFF"
              />
            </Box>

            <Text color={"#8E8E8E"} fontSize="10px" fontWeight={"500"}>
              Paid using UPI Payment{" "}
            </Text>
            <Text color={"#707070"} fontSize="10px" fontWeight={"400"}>
              Tracking ID : DTDC - 7894575642157
            </Text>
            <Text color={"#404040"} fontSize="16px" fontWeight={"700"}>
              Rs. 1,00,000
            </Text>
          </Box>
        </Box>
      )}
      </Box>
    </Box>
  );
};

export default MyOrders;
