// A mock function to mimic making an async request for data
import axios from "axios";

export function fetchSubcategory() {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      process.env.REACT_APP_BASE_API+ "/subcategory/getSubcategory"
    );
    const data = response.data;
    resolve({ data });
  });
}
export function fetchSubCategoryById(subcategoryId) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/subcategory/getSubcategoryById",
      {
        subcategoryId: subcategoryId,
      }
    );

    const data = response.data;
    resolve({ data });
  });
}
export function fetchSubCategoryNameByCategoryId(categoryId) {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API}/subcategory/getSubcategoryName/${categoryId}`
    );

    const data = response.data;
    resolve({ data });
  });
}
export function fetchSubCategoryByCategoryId(categoryId) {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API}/subcategory/getSubcategoryByCategoryId/${categoryId}`
    );

    const data = response.data;
    resolve({ data });
  });
}
