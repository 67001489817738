import { Box, Flex, HStack, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { contactIcon, locationIcon } from "../component/constant/Icons";
import ContactForm from "../component/Form/ContactForm";

const Contact = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box 
      display={"flex"}
      p={[0,0,0,20,0]}
      flexDirection={["column", "column", "row", "row", "row"]}
      justifyContent={"center"}
      paddingTop={["0px", "0px", "0px", "50px", "80px"]}
      background="linear-gradient(180deg, #f3ffe1 0%, #fff 100%), #f3ffe1"
      paddingBottom={["0px", "0px", "0px", "50px", "150px"]}
    >
      <Box   w={["100%","100%","50%","50%","50%"]}     >
        <Box p={["20px", "0px"]} >
          <Text 
            color={"#404040"}
            fontFamily="Inter"
            fontSize={["30px", "30px", "50px", "50px", "86px"]}
            fontStyle="normal"
            fontWeight={"700"}
            mt={["5px", "5px","20px", "0px", "80px"]}
          >
            Contact us
          </Text>
          <Text 
            color={"#707070"}
            fontFamily="Inter"
            fontSize={["10px", "10px", "12px", "13px", "16px"]}
            fontStyle="normal"
            fontWeight={"700"}
            width={["auto","auto","auto","300px", "450px"]}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
            convallis mauris ut lectus dictum, sit amet ultricies sapien
            convallis.
          </Text>
        </Box>
        {!isMobile && (
          <Flex    justifyContent={"space-between"} gap={"20px"}
            display={"flex"}
            marginTop={["5px", "20px",  "200px", "170px", "170px"]}
          >
            <Box >
              {locationIcon()}
              <Text
                color={"#404040"}
                fontFamily="Inter"
                fontSize={["10px", "14px", "18px", "20px", "24px"]}
                fontStyle="normal"
                fontWeight={"700"}
              >
                Our Address
              </Text>
              <Text
                color={"#707070"}
                fontFamily="Inter"
                fontSize={"16px"}
                fontStyle="normal"
                fontWeight={"700"}
                width={["100%","100%","100%","100%","350px"]}
                mt={"10px"}
              >
                Lorem ipsum dolor sit amet, consectetur. Proin convallis mauris
                ut lectus dictum, sit amet ultricies sapien convallis.{" "}
              </Text>
            </Box>
            <Box  width={["200px","200px","200px","300px","450px"]}>
              {contactIcon()}
              <Text 
                color={"#404040"}
                fontFamily="Inter"
                fontSize={["10px", "14px", "18px", "20px", "24px"]}
                fontStyle="normal"
                fontWeight={"700"}
              >
                Contact Us
              </Text>
              <Text 
                color={"#707070"}
                fontFamily="Inter"
                fontSize={["6px", "10px", "12px", "13px", "16px"]}
                fontStyle="normal"
                fontWeight={"700"}
                mt={"15px"}
                  
                w={"200px"} 
              >
                +91 2457845412234
              </Text>
              <Text
                color={"#707070"}
                fontFamily="Inter"
                fontSize={["6px", "10px", "12px", "13px", "16px"]}
                fontStyle="normal"
                fontWeight={"700"}
                
                mt={"15px"}
              >
                sonam@gmail.com
              </Text>
            </Box>
          </Flex>
        )}
      </Box>
      <Box 
        border={"1px solid #ccc"}
        boxShadow={"0px 4px 4px 4px rgba(0, 0, 0, 0.25)"}
        borderRadius={"28px"}
        bg={"#fff"}
        p={{base:"20px", lg:"0px"}}
        m={{base:"10px", lg:"0px"}}
      >
        <ContactForm />
      </Box>
    </Box>

  );
};

export default Contact;
