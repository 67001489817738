import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Heading,
  Text,
} from '@chakra-ui/react';

const Faq = () => {
  return (
    <Box bg={"linear-gradient(180deg, #f3ffe1 0%, #fff 100%)"}>
    <Box padding={4} maxW="6xl" mx="auto">
      <Heading as="h1" mb={6}>
        Frequently Asked Questions
      </Heading>

      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                My Account & My Orders
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text fontWeight="bold">What is 'My Account'? How do I update my information?</Text>
            <Text>
              It is easy to update your Sonam Agrotech pvt ltd. account and view your orders any time through 'My Account'.
              'My Account' allows you complete control over your transactions on sonamagrotech.com.
            </Text>
            <ul>
              <li>Manage/edit your personal data like address, phone numbers, email ids</li>
              <li>Change your password</li>
              <li>Track the status of your orders</li>
            </ul>
            <Text fontWeight="bold">How do I know my order has been confirmed?</Text>
            <Text>
            Once your order has been placed by you or authorized agent then you will see it in your order Detail page in your account.
            </Text>

            <Text fontWeight="bold">Can I order a product that is 'Out of Stock'?</Text>
            <Text>
            Unfortunately, products listed as 'Out of Stock' are not available for sale.
            </Text>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Shopping
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text fontWeight="bold">Why do I see different prices for the same product?</Text>
            <Text>
              A product could be listed under different prices due to sellers offering the same product at different
              prices. This is the nature of the Sonam Agrotech pvt ltd. marketplace, where different sellers compete for your order,
              providing maximum benefits for buyers.
            </Text>

            <Text fontWeight="bold">Is it necessary to have an account to shop on Sonam Agrotech?</Text>
            <Text>
            You can shop on Sonam Agrotech without online account. But you have to give all detail when you place order online. It is recommended that you create one account. By creating your own Sonam Agrotech account, you can enjoy a personalized shopping experience, including recommendations and benefits of valuable schemes.
            </Text>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
  <h2>
    <AccordionButton>
      <Box flex="1" textAlign="left">Payments</Box>
      <AccordionIcon />
    </AccordionButton>
  </h2>
  <AccordionPanel pb={4}>
    {/* Sonam Agrotech Payment Info */}
    <Text fontWeight="bold">How do I pay for a Sonam Agrotech pvt ltd. purchase?</Text>
    <Text>
    Sonam Agrotech pvt ltd. offers you multiple payment methods. Rest assured that Sonam Agrotech pvt ltd.'s trusted payment gateway partners use secure encryption technology to keep your transaction details confidential at all times.
    </Text>
    <Text>You may use Internet Banking and Cash on Delivery to make your purchase. Sonam Agrotech pvt ltd. also accepts payments made using Visa, MasterCard, Maestro and American Express credit/debit cards in India.</Text>

    <Text fontWeight="bold" mt={4}>Are there any hidden charges (Octroi or Sales Tax) when I make a purchase on Sonam Agrotech pvt ltd.?</Text>
    <Text>
      There are NO hidden charges when you make a purchase on Sonam Agrotech pvt ltd.. The prices listed for all the items are final and all-inclusive.
      The price you see on the product page is exactly what you pay.
    </Text>

    <Text fontWeight="bold" mt={4}>What is Cash on Delivery?</Text>
    <Text>
      If you are not comfortable making an online payment on Sonam Agrotech pvt ltd., you can opt for the Cash on Delivery (COD) payment method.
      With COD, you can pay in cash at the time of actual delivery of the product at your doorstep, without requiring you to make
      any advance payment online.
      The maximum order value for a Cash on Delivery (COD) payment is Rs. 50,000 (depending on order). It is strictly a cash-only payment method.
      Only Indian Rupees are accepted in the COD method.
    </Text>

    <Text fontWeight="bold" mt={4}>How do I pay using a credit/debit card?</Text>
    <Text>We accept payments made by credit/debit cards issued in India.</Text>
    <Text>Credit cards We accept payments made using Visa, MasterCard and American Express credit cards. To pay using your credit card at checkout, you will need your card number, expiry date, three-digit CVV number (found on the backside of your card). After entering these details, you will be redirected to the bank's page for entering the online 3D Secure password.</Text>
    <Text>Debit cards We accept payments made using Visa, MasterCard and Maestro debit cards. To pay using your debit card at checkout, you will need your card number, expiry date (optional for Maestro cards), three-digit CVV number (optional for Maestro cards). You will then be redirected to your bank's secure page for entering your online password (issued by your bank) to complete the payment.
</Text>

    <Text fontWeight="bold" mt={4}>Is it safe to use my credit/debit card on Sonam Agrotech pvt ltd.?</Text>
    <Text>
      Your online transaction on Sonam Agrotech pvt ltd. is secure with the highest levels of transaction security currently available on the Internet.
      Sonam Agrotech pvt ltd.'s online payment partner uses 256-bit encryption technology to protect your card information while securely transmitting it to the respective banks for payment processing.
     
    </Text>
<Text> All credit card and debit card payments on Sonam Agrotech pvt ltd. are processed through secure and trusted payment gateways managed by leading banks.
      Banks now use the 3D Secure password service for online transactions, providing an additional layer of security through identity verification.</Text>
    <Text fontWeight="bold" mt={4}>What steps does Sonam Agrotech pvt ltd. take to prevent card fraud?</Text>
    <Text>
    Sonam Agrotech pvt ltd. realizes the importance of a strong fraud detection and resolution capability. We and our online payments partners monitor transactions continuously for suspicious activity and flag potentially fraudulent transactions for manual verification by our team.
    </Text>
    <Text>In the rarest of rare cases, when our team is unable to rule out the possibility of fraud categorically, the transaction is kept on hold, and the customer is requested to provide identity documents. The ID documents help us ensure that the purchases were indeed made by a genuine card holder. We apologies for any inconvenience that may be caused to customers and request them to bear with us in the larger interest of ensuring a safe and secure environment for online transactions.</Text>

    <Text fontWeight="bold" mt={4}>What is a 3D Secure password?</Text>
    <Text>
      The 3D Secure password is implemented by VISA and MasterCard in partnership with card issuing banks under the 'Verified by VISA' and 'Mastercard SecureCode' services, respectively.
      The 3D Secure password adds an additional layer of security through identity verification for your online credit/debit card transactions.
      This password, which is created by you, is known only to you. This ensures that only you can use your card for online purchases.
    </Text>

    <Text fontWeight="bold" mt={4}>Can I use my bank's Internet Banking feature to make a payment?</Text>
    <Text>
      Yes. Sonam Agrotech pvt ltd. offers you the convenience of using your bank's Internet Banking service to make a payment towards your order. With this, you can directly transfer funds from your
      bank account while conducting a highly secure transaction.
    </Text>

    <Text fontWeight="bold" mt={4}>Can I make a credit/debit card or Internet Banking payment on Sonam Agrotech pvt ltd. through my mobile?</Text>
    <Text>
      Yes, you can make credit card payments through Sonam Agrotech pvt ltd.'s mobile site and application. Sonam Agrotech pvt ltd. uses 256-bit encryption technology to protect your card information
      while securely transmitting it to the secure and trusted payment gateways managed by leading banks.
    </Text>

    <Text fontWeight="bold" mt={4}>How do I place a Cash on Delivery (COD) order?</Text>
    <Text>
      All items that have the 'Cash on Delivery Available' icon are valid for order by cash on delivery. Add the item(s) to your cart and proceed to checkout.
      Once verified and confirmed, your order will be processed for shipment in the specified time from the date of confirmation.
      You will be required to make a cash-only payment to our courier partner at the time of delivery to complete the payment. Terms & Conditions
    </Text>
  </AccordionPanel>
</AccordionItem>

<AccordionItem>
  <h2>
    <AccordionButton>
      <Box flex="1" textAlign="left">Order Status</Box>
      <AccordionIcon />
    </AccordionButton>
  </h2>
  <AccordionPanel pb={4}>
    <Text fontWeight="bold">How do I check the current status of my orders?</Text>
    <Text>
      You can review the status of your orders and other related information in the 'My Account' section. 
      Click on the 'My Orders' link to view the status of all your orders. Alternatively, you can call: 7428208822.
    </Text>

    <Text fontWeight="bold" mt={4}>What do the different order status mean?</Text>
    <Box>
  <Text>
    <Text as="span" fontWeight="bold">Pending</Text>: Your order has been placed but the product has not yet been shipped.
  </Text>
  <Text>
    <Text as="span" fontWeight="bold">Reject</Text>: Your order has been rejected by the seller or Sonam Agrotech pvt ltd.
  </Text>
  <Text>
    <Text as="span" fontWeight="bold">Deleted</Text>: Your order has been deleted or canceled by yourself.
  </Text>
  <Text>
    <Text as="span" fontWeight="bold">Payment Received</Text>: Authorization has been received from the payment gateway, and your order is being processed by the seller.
  </Text>
  <Text>
    <Text as="span" fontWeight="bold">Shipped</Text>: Your order has been shipped by the seller and is on its way to the location specified by you for delivery.
  </Text>
  <Text>
    <Text as="span" fontWeight="bold">Complete</Text>: You have already received the product.
  </Text>
  <Text>
    <Text as="span" fontWeight="bold">Return</Text>: The product was delivered but has been returned by yourself.
  </Text>
</Box>

    <Text fontWeight="bold" mt={4}>When and how can I cancel an order?</Text>
    <Text>
      You can cancel your order before it reaches the 'Shipped' state. To cancel, please press the delete icon on the 'My Orders' page.
    </Text>
  </AccordionPanel>
</AccordionItem>

     
<AccordionItem>
  <h2>
    <AccordionButton>
      <Box flex="1" textAlign="left">Shipping</Box>
      <AccordionIcon />
    </AccordionButton>
  </h2>
  <AccordionPanel pb={4}>
    <Text fontWeight="bold">What are the delivery charges?</Text>
    <Text>
      Delivery charge varies with each seller, but it's already included in the product price. There are zero hidden
      charges; list prices are final and all-inclusive.
    </Text>

    <Text fontWeight="bold" mt={4}>Are there any hidden costs (sales tax, octroi, etc.) on items sold by sellers?</Text>
    <Text>
      No. There are zero hidden charges when you make a purchase on Sonam Agrotech pvt ltd.. The list prices are final and all-inclusive.
      The price you see on the product page is exactly what you would pay.
    </Text>

    <Text fontWeight="bold" mt={4}>What is the estimated delivery time?</Text>
    <Text>
      Sellers or Sonam Agrotech pvt ltd. generally procure and ship the items within the time specified on the product page. Business days exclude public holidays and Sundays.

      Estimated delivery time depends on the following factors:
      1. The seller offering the product.
      2. Product's availability with the seller.
      3. The destination to which you want the order shipped and the geographical location of the seller.
    </Text>

    <Text fontWeight="bold" mt={4}>Why does the estimated delivery time vary from seller to seller?</Text>
    <Text>
      You have probably noticed varying estimated delivery times for sellers of the product you're interested in. Delivery times are influenced by product availability, the geographic location of the seller, your shipping destination, and the courier partner's time-to-deliver in your location.

      Please enter your default pin code on the product page (you don't have to enter it every time) to know more accurate delivery times on the product page itself.
    </Text>

    <Text fontWeight="bold" mt={4}>Seller does not/cannot ship to my area. Why?</Text>
    <Text>
      Whether your location can be serviced or not depends on:
      - If the seller ships to your location.
      - Legal restrictions, if any, on shipping particular products to your location.
      - The availability of reliable courier partners in your location.
      
      Sometimes, sellers prefer not to ship to certain locations. This is entirely at their discretion.
    </Text>

    <Text fontWeight="bold" mt={4}>I need to return an item. How do I arrange for a pick-up?</Text>
    <Text>
      Returns are easy. Contact Sonam Agrotech pvt ltd. to initiate a return, and you will receive a call explaining the process. If pick-up cannot be arranged through Sonam Agrotech pvt ltd., you can return the item through a third-party courier service. Return fees are borne by the seller.
    </Text>

    <Text fontWeight="bold" mt={4}>Does Sonam Agrotech pvt ltd. deliver internationally?</Text>
    <Text>
      As of now, Sonam Agrotech pvt ltd. doesn't deliver items internationally.
    </Text>
  </AccordionPanel>
</AccordionItem>

<AccordionItem>
  <h2>
    <AccordionButton>
      <Box flex="1" textAlign="left">Courier and Delivery</Box>
      <AccordionIcon />
    </AccordionButton>
  </h2>
  <AccordionPanel pb={4}>
    <Text fontWeight="bold">How does the delivery process work?</Text>
    <Text>
      All deliveries are scheduled through reputed couriers or Indian post. You'll be informed when the seller ships your consignment and receive a tracking number.
      During checkout, you will be prompted to provide your location's pin code to ensure that the seller delivers to you.
    </Text>

    <Text fontWeight="bold" mt={4}>How are items packaged?</Text>
    <Text>
      Products sold by sellers follow standard packing procedures. Sellers are rated on packaging quality, and it affects their overall seller rating.
    </Text>

    <Text fontWeight="bold" mt={4}>Are in-transit products insured?</Text>
    <Text>
      Only a few costly materials are insured against theft and damages incurred during transit.
    </Text>

    <Text fontWeight="bold" mt={4}>How can I track the delivery of my order?</Text>
    <Text>
      All items sold by sellers on Sonam Agrotech pvt ltd. are delivered through reputed courier partners or Indian post.
      You will be provided a Tracking ID for your order by the respective courier service provider or Indian post via our website.
    </Text>

    <Text fontWeight="bold" mt={4}>Cancellation and Returns</Text>
    
    <Text fontWeight="bold">What is Sonam Agrotech pvt ltd.'s Friendly Returns policy?</Text>
    <Text>
      At Sonam Agrotech pvt ltd., we understand how much you value your purchase. If you're not fully satisfied with your order, you can conveniently initiate a return online.
      We ensure you get a resolution as quickly as possible. You can also cancel your order online if it hasn't reached the 'Shipped' status.
      If the material you received is 'Low quality' or 'Not as Described', our Friendly Returns policy allows for a replacement or a refund. You have 7 days to initiate a replacement or return.
    </Text>

    <Text fontWeight="bold" mt={4}>When are returns not possible?</Text>
    <Text>
      There are certain scenarios where it is difficult for us to support returns, such as:
      - The return request is made outside the specified time frame.
      - The materials have been used.
      - Products with tampered or missing serial numbers.
      - Anything missing from the package, including price tags, labels, or original packing.
    </Text>

    <Text fontWeight="bold" mt={4}>Can I return/cancel one product out of many ordered?</Text>
    <Text>
      Yes, you can. We assign a unique order number for each product, allowing you to return or cancel any one item from a multi-product order.
    </Text>

    <Text fontWeight="bold" mt={4}>When are refunds provided?</Text>
    <Text>
      Refunds are issued when:
      - The seller cannot provide a replacement.
      - In case of a dispute, when the dispute has been ruled in your favor.
    </Text>

    <Text fontWeight="bold" mt={4}>I have changed my mind and would like to retain the product. What do I do?</Text>
    <Text>
      If you wish to cancel your return request, call us, and we will handle it. If the replacement is already dispatched, you can reject it when delivered.
    </Text>

    <Text fontWeight="bold" mt={4}>How do I cancel an order?</Text>
    <Text>
      You can cancel your order online before the product has been shipped. If it has been shipped but not yet delivered, you can still cancel online. 
      However, once delivered, an order cannot be canceled.
      To cancel an item in your order:
      - Log into your Sonam Agrotech pvt ltd. account and go to the 'My Orders' page.
      - Find the order you want to cancel and click on the corresponding 'Delete' icon.
      - Refresh the 'My Orders' page to confirm the "Deleted" status for that order.
    </Text>

    <Text fontWeight="bold" mt={4}>How long will it take to process my cancellation request?</Text>
    <Text>
      Cancellation requests are processed immediately.
    </Text>
  </AccordionPanel>
</AccordionItem>
      </Accordion>
    </Box></Box>
  );
};

export default Faq;
