import React, { useEffect } from 'react';
import { Box, Text, Image, Center, VStack } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

const NoResultFound = () => {
    const location = useLocation();
    const { message } = location.state || {};
    const navigate = useNavigate();
    useEffect(() => {
        const timer = setTimeout(() => {
          navigate('/govtscheme');
        }, 3000); 
    
        return () => clearTimeout(timer); // Cleanup the timer on component unmount
      }, [navigate]);
  return (
    <Center height={["auto","auto","auto","auto","auto","80vh"]} bg="gray.50">
      <Box 
        textAlign="center" 
        p={8} 
        borderWidth={1} 
        borderRadius="lg" 
        boxShadow="lg" 
        bg="white"
      >
        <VStack spacing={4}>
          <Image 
            src="https://cdni.iconscout.com/illustration/premium/thumb/employee-is-unable-to-find-sensitive-data-9952946-8062130.png?f=webp" 
            alt="No Results Found" 
            boxSize="200px" 
            mb={4}
          />
          <Text fontSize={["2xl","2xl","2xl","2xl","4xl"]} fontWeight="bold" color="teal.500">
            No Results Found!
          </Text>
          <Text color="gray.500">
            {/* We couldn't find what you were looking for. Please try searching again with different terms. */}
{message}
          </Text>
        </VStack>
      </Box>
    </Center>
  );
};

export default NoResultFound;
