// A mock function to mimic making an async request for data
import axios from "axios";
export function createGovermentschme(Govermentschme) {
  return new Promise(async (resolve) => {
  const response = await axios.post(process.env.REACT_APP_BASE_API+"/govermentschme/addGovermentschme", Govermentschme);
    const data = response.data;

    resolve({ data });
  });
}


export function fetchGovermentschme(page) {
  return new Promise(async (resolve) => {
    const response = await axios.get(
     `${process.env.REACT_APP_BASE_API}/govermentschme/getGovermentschme?page=${page}`
    );
    const data = response.data;
    resolve({ data });
  });
}

export function fetchGovermentschmeById(govermentschmeId) {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API}/govermentschme/getGovermentschmeById/${govermentschmeId}`
    );

    const data = response.data;
    resolve({ data });
  });
}
export async function fetchGovermentschmeByState({ govermentschmeState, currentPage,type }) {
    return new Promise(async (resolve) => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API}/govermentschme/getGovermentschmeByState/${govermentschmeState}?page=${currentPage}&type=${type}`
      );

      const data = response.data;
       resolve({ data });
     
    });
  
  }