import { Box, Skeleton, SkeletonText } from "@chakra-ui/react";
import React from "react";

const ServicePageSkeleton = () => {
  return (
    
      <Box p={[2, 0]}>
        {new Array(4).fill("loading").map((el, i) => (
          <Box key={i}
            display={["flex", "flex", "flex", "flex", "grid"]}
            gridTemplateColumns={["1fr", "1fr", "1fr", "1fr", "2fr 1fr"]}
            w={["auto", "auto", "auto", "auto", "1300px"]}
            justifyContent={"center"}
            gap={"20px"}
            h={"auto"}
            margin={"auto"}
            mt={["10px", "40px"]}
            p={["10px", "30px"]}
            borderRadius={"24px"}
            bg={"#fff"}
            mb={["10px", "80px"]}
            boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
            flexDirection={{
              base: "column-reverse",
              sm: "column-reverse",
              md: "column-reverse",
              lg: "column-reverse",
            }}
          >
            <Box>
              <Skeleton h={7} w={"40%"} mb={5}></Skeleton>
              <SkeletonText
                mt="4"
                noOfLines={7}
                spacing="4"
                skeletonHeight="2"
                w={["auto", "auto", "auto", "auto", "700px"]}
              />
              <Skeleton
                width={"30%"}
                borderRadius="7px"
                h={10}
                mt={20}
              ></Skeleton>
            </Box>
            <Skeleton
              mt={["5px", "20px"]}
              w={"100%"}
              h={"250px"}
              borderRadius={"25px"}
            ></Skeleton>
          </Box>
        ))}
      </Box>
   
  );
};

export default ServicePageSkeleton;
