import { useState } from "react";
import { CgSpinner } from "react-icons/cg";
import {
  HStack,
  Image,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  PinInput,
  PinInputField,
  useDisclosure,
} from "@chakra-ui/react";
import "./ModalBox.css";
// import OtpInput from "otp-input-react";
import indianFlagImage from "../../images/IndianFlag.png";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Text,
  Input,
  Flex,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import {
  checkMobilNoAsync,
  otpVerifyUserAsync,
  registerUserAsync,
} from "../../features/User/UserSlice";
import { toast, Toaster } from "react-hot-toast";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

function ModalBox() {
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState(1);
  const [fullName, SetName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [password, SetPassword] = useState("");
  const [mobileNo, setMobileNo] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const dispatch = useDispatch();
  const handleChange = (index, value) => {
    const newOtp = otp.slice(0, index) + value + otp.slice(index + 1);
    setOtp(newOtp);
  };

  const onOTPVerify = (mobile, otp) => {
    dispatch(otpVerifyUserAsync({ mobile, otp })).then((res) => {
      if (res?.payload.status === 1) {
        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };
  const sendOtp = async () => {
    let data = {mobileNo,password,fullName}
    dispatch(registerUserAsync(data)).then((res) => {
      if (res?.payload.status === 1) {
        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };
  const handleContinue = async () => {
    try {
      if (step === 1) {
        if (mobileNo) {
          if (password.length >= 6) {
            const response = await dispatch(checkMobilNoAsync(mobileNo));

            if (response?.payload?.status === 1) {
              
              await sendOtp();
              setStep((prevStep) => prevStep + 1);
            } else {
              toast.error(response?.payload?.message);
            }
          } else {
            toast.error("Password must be at least 6 characters long.");
          }
        } else {
          toast.error("Please enter a valid phone number.");
        }
      } else if (step === 2) {
        onOTPVerify(mobileNo, otp);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleStepClick = async (stepNumber) => {
    setStep(stepNumber);
  };

  return (
    <>
      <Text id="newusers" onClick={onOpen}>
        {" "}
        Don't have an account? <span id="newuser">Sign up!</span>
      </Text>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          style={{
            backdropFilter: "blur(2px)", // Apply the backdrop blur effect
          }}
        />
        <ModalContent
           width={["90%", "700px"]}
           height={["auto", "550px"]}
          borderRadius="28px"
          background="#fff"
          padding={[2, 10]}
        >
          <ModalCloseButton
            backgroundColor="#8E8E8E"
            borderRadius="50%"
            color="white"
          />
          <ModalBody>
            {/* Progress Bar */}
            <Flex
              width="320px"
              height="15px"
              gap={"10px"}
              borderRadius="50px"
              mt={{base:"8",lg:"4"}}
            >
              {/* Step 1 */}
              <Box
                onClick={() => handleStepClick(1)}
                width="50%"
                height="100%"
                borderRadius="50px"
                background="#52771A"
              />

              {/* Step 2 */}
              <Box
                onClick={() => handleStepClick(2)}
                width="50%"
                height="100%"
                borderRadius="50px"
                background={step >= 2 ? "#52771A" : "#F3FFE1"}
              />

              {/* Step 3 */}
              {/* <Box
                onClick={() => handleStepClick(3)}
                width="33.33%"
                height="100%"
                borderRadius="50px"
                background={step === 3 ? "#52771A" : "#F3FFE1"}
              /> */}
            </Flex>

            {/* Step Text */}
            <Text
              color="#404040"
              fontFamily="Inter"
              fontSize="32px"
              fontStyle="normal"
              fontWeight="700"
              lineHeight="1.5"
              mt="4"
            >
              {step === 1 && "Sign up"}
              {step === 2 && "Step 2"}
              {/* {step === 3 && "Step 3"} */}
            </Text>
            {step === 1 && (
              <Text id="normal_text">Please provide your details</Text>
            )}
            {/* Step Content */}
            {step === 1 && (
              <>
                {step === 1 && <Toaster toastOptions={{ duration: 4000 }} />}
                <Text
                  color="#404040"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  mt={4}
                >
                  Name
                </Text>
                <Input
                  focusBorderColor="#0336FF"
                  placeholder="Enter your name"
                  mt="2"
                  borderRadius={"14px"}
                  border=" 1px solid #A0A0A0"
                  onChange={(e) => SetName(e.target.value)}
                />
                <Text
                  color="#404040"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  mt="4"
                >
                  Mobile No
                </Text>
                <InputGroup>
                  <InputLeftAddon borderRadius={"14px 0px 0px 14px"} border=" 1px solid #A0A0A0">
                    <Image
                      src={indianFlagImage}
                      borderRadius="full"
                      boxSize="25px"
                      alt="+91"
                    />
                  </InputLeftAddon>
                  <Input
                    type="number"
                    placeholder="phone number"
                    focusBorderColor="#0336FF"
                    // mt="2"
                    borderRadius={"14px"}
                    border=" 1px solid #A0A0A0"
                    value={mobileNo}
                    onChange={(event) => setMobileNo(event.target.value)}
                  />
                </InputGroup>
                {/* <Input
                  focusBorderColor="#0336FF"
                  mt="2"
                  borderRadius={"14px"}
                  border=" 1px solid #A0A0A0"
                  value={mobileNo}
                  onChange={(event) => setMobileNo(event.target.value)}
                /> */}
                <Text
                  color="#404040"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  mt="4"
                >
                  Create Password
                </Text>
                {/* <Input
                  type="password"
                  mt="2"
                  focusBorderColor="#0336FF"
                  onChange={(e) => SetPassword(e.target.value)}
                  borderRadius={"14px"}
                  border=" 1px solid #A0A0A0"
                /> */}

                <InputGroup size="md">
                  <Input
                    focusBorderColor="#0336FF"
                    w={"100%"}
                    pr="4.5rem"
                    type={show ? "text" : "password"}
                    placeholder="Enter password"
                    onChange={(e) => SetPassword(e.target.value)}
                    borderRadius={"14px"}
                    border=" 1px solid #A0A0A0"
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </>
            )}

            {step === 2 && (
              <>
                {step === 2 && <Toaster toastOptions={{ duration: 4000 }} />}
                {/* <Text
                  color="#404040"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  mt="4"
                >
                  Mobile No
                </Text>
                <PhoneInput
                  country={"in"}
                  value={mobileNo}
                  onChange={SetMobileNo}
                /> */}

                {/* <Text
                  color="#404040"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  mt="4"
                >
                  Password
                </Text>
                <Input
                  type="password"
                  mt="2"
                  focusBorderColor="#0336FF"
                  onChange={(e) => SetPassword(e.target.value)}
                /> */}
              </>
            )}

            {step === 2 && (
              <>
                {step === 2 && <Toaster toastOptions={{ duration: 4000 }} />}

                <Text
                  color="#404040"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  mt="4"
                >
                  Enter 6 Digit OTP
                </Text>
                <HStack mt={5}>
                  <PinInput>
                    {Array.from({ length: 6 }).map((_, index) => (
                      <PinInputField
                        key={index}
                        value={otp[index] || ""}
                        onChange={(e) => handleChange(index, e.target.value)}
                      />
                    ))}
                  </PinInput>
                </HStack>
                {/* <OtpInput
                  value={otp}
                  onChange={setOtp}
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  autoFocus
                ></OtpInput> */}
              </>
            )}

            <Button bg={"#52771A"} borderRadius={"14px"} w={"full"} mt={"30px"} color={"#f3ffe1"} fontFamily={"Inter"} fontSize={"16px"} fontWeight={500} onClick={handleContinue}>
              {loading && <CgSpinner size={20} className="mt-1 animate-spin" />}
              <span>{step === 2 ? "Verify OTP" : "Send OTP"}</span>
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalBox;
