import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchGovermentschme,
  fetchGovermentschmeById,
  fetchGovermentschmeByState,

} from "./GovermentschmeAPI";

const initialState = {
  value: 0,
  status: "idle",
  govermentschmeData: [],
  admingovermentschmeData: {data:{items:[]}},
};

export const fetchGovermentschmeAsync = createAsyncThunk(
  "govermentschme/fetchGovermentschme",
  async (page) => {
    const response = await fetchGovermentschme(page);

    return response.data;
  }
);
export const fetchGovermentschmeByIdAsync = createAsyncThunk(
  "govermentschme/fetchGovermentschmeById",
  async (govermentschmeId) => {
    const response = await fetchGovermentschmeById(govermentschmeId);

    return response.data;
  }
);
export const fetchGovermentschmeByStateAsync = createAsyncThunk(
  "govermentschme/fetchGovermentschmeByState",
  async ({ govermentschmeState, currentPage ,type}) => {
    const response = await fetchGovermentschmeByState({
      govermentschmeState,
      currentPage,
      type
    });
    return response.data;
  }
);


export const GovermentschmeSlice = createSlice({
  name: "govermentschme",
  initialState,

  reducers: {
    increment: (state) => {
      state.value += 1;
    },
  },

  extraReducers: (builder) => {
    builder
      
      .addCase(fetchGovermentschmeAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGovermentschmeAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.govermentschmeData = action.payload;
      })
      
      .addCase(fetchGovermentschmeByIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGovermentschmeByIdAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.govermentschmeData = action.payload;
      })
      .addCase(fetchGovermentschmeByStateAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGovermentschmeByStateAsync.fulfilled, (state, action) => {
        state.status = "idle";
         state.admingovermentschmeData = action.payload;
         state.govermentschmeData = action.payload;
      })
  
  },
});

// export const { increment } = UserSlice.actions;

export const selectGovermentschme = (state) => state.govermentschme.govermentschmeData;


export default GovermentschmeSlice.reducer;
