import { Box, Button, Collapse, Image, Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import Pills from "../../component/constant/Pills";
import ContactUsModal from "../../component/Modal/ContactUsModal";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
const schemecardStyle = {
  name: {
    color: "#404040",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  btn: {
    borderRadius: "14px",
    background: "#52771A",
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
};
const SchemeCard = ({ name, state, content, image }) => {
  const [show, setShow] = useState(false);

  const characterLimit = 500; // Adjust this as needed
  const handleToggle = () => setShow(!show);
  const isContentLong = content.length > characterLimit;
  return (
    <Box style={schemecardStyle.main_box}   width="100%" >
      <Box  bg= "#FFF" border={"1px solid #d2e3bf"}   borderRadius= "24px" padding={["20px","40px"]}>   
      <Box display={"flex"} justifyContent="space-between">
        <Text fontSize={["20px","20px","30px","34px","36px"]} style={schemecardStyle.name}>{name}</Text>
        <Pills name={state} />
      </Box>

      <Box  mt={["20px","42px"]} mb={["20px","42px"]}  >
        <Image src={image} w={["100%","100%","348px","448px","548px"]} h={["148px","248px","276px","276px","276px"]} borderRadius={"14px"} m="auto" />
      </Box>
      <Box >
        <Collapse startingHeight={190} in={show}>
          <Text
            color="#404040"
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            lineHeight={"24px"}
            textAlign={"justify"}
          >
            {content}
          </Text>
        </Collapse>
        {isContentLong && (
          <Button
            size="sm"
            onClick={handleToggle}
            variant="ghost"
            mt={"10px"}
            color="#52771A"
            fontSize={"16px"}
            fontWeight="600"
            fontFamily={"Inter"}
            rightIcon={
              show ? (
                <ChevronUpIcon color={"#52771A"} w="15" h={"9"} />
              ) : (
                <ChevronDownIcon color={"#52771A"} w="15" h={"9"} />
              )
            }
          >
            {show ? "Read Less" : "Read More"}
          </Button>
        )}
      </Box>
      <Stack />

      <Box mt={!isContentLong && "40px"} display="flex" justifyContent={"end"} mr={2} >
        <ContactUsModal
          contact={"GovermentScheme Contact"}
          heading={name}
          state={state}
          stateEditable = {true}
          />
      </Box>
          </Box>
    </Box>
  );
};

export default SchemeCard;
// #d2e3bf  color code for border if needed
