import React from 'react';
import {
  Box,
  Container,
  Heading,
  Image,
  ListItem,
  OrderedList,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';

const TermsOfUse = () => {
  const phoneNumber = "9142429858";
  const formattedNumber = `tel:${phoneNumber}`;
  const emailAddress = "info@sonamagrotech.com";
  const mailtoLink = `mailto:${emailAddress}`;
  const handlePhoneClick = () => {
    window.location.href = formattedNumber;
  };
  return (
    <Box bg={"linear-gradient(180deg, #f3ffe1 0%, #fff 100%)"}>
    <Container maxW="container.xl" p={6}>
      {/* Banner Image */}
      <Box mb={6}>
        <Image
          src="https://admissionmall.com/images/Term-and-Condition.webp"
          alt="Terms of Use Banner"
          borderRadius="md"
          objectFit="content"
          width="100%"
          height="250px"
        />
      </Box>

      {/* Page Content */}
      <Stack spacing={5}>
        <Heading as="h1" size="xl">
          Terms of Use
        </Heading>

        <Text fontSize="md">
          Welcome to our website. If you continue to browse and use this
          website, you are agreeing to comply with and be bound by the following
          terms and conditions of use, which together with our privacy policy
          govern Sonam Agrotech Pvt Ltd. relationship with you in relation
          to this website.
        </Text>

        <Text fontSize="md">
          The term 'Sonam Agrotech Pvt Ltd.' or 'us' or 'we' refers to the
          owner company of the website whose registered office is FLAT NO. 602, KUMAR RANJAN ENCLAVE, NEAR BAHADURPUR FLYOVER, KANKARBAGH, Patna, Bihar, 800020. Our company registration
          number is U25209BR2021PTC053357 patna. The term 'you' refers to the
          user or viewer of our website.
        </Text>

        <Text fontSize="md">
          The use of this website is subject to the following terms of use:
        </Text>

        <UnorderedList pl={6}>
        <ListItem>
            The content of the pages of this website is for your general
            information and use only. It is subject to change without notice.
          </ListItem>
          <ListItem>
            Neither we nor any third parties provide any warranty or guarantee
            as to the accuracy, timeliness, performance, completeness, or
            suitability of the information and materials found or offered on
            this website for any particular purpose. You acknowledge that such
            information and materials may contain inaccuracies or errors, and
            we expressly exclude liability for any such inaccuracies or errors
            to the fullest extent permitted by law.
          </ListItem>
          <ListItem>
            Your use of any information or materials on this website is entirely
            at your own risk, for which we shall not be liable. It shall be your
            own responsibility to ensure that any products, services, or
            information available through this website meet your specific
            requirements.
          </ListItem>
          <ListItem>
            This website contains material that is owned by or licensed to us.
            This material includes, but is not limited to, the design, layout,
            look, appearance, and graphics. Reproduction is prohibited other
            than in accordance with the copyright notice, which forms part of
            these terms and conditions.
          </ListItem>
          <ListItem>
            All trademarks reproduced on this website that are not the property
            of, or licensed to, the operator, are acknowledged on the website.
          </ListItem>
          <ListItem>
            Unauthorized use of this website may give rise to a claim for
            damages and/or be a criminal offense.
          </ListItem>
          <ListItem>
            From time to time, this website may also include links to other
            websites. These links are provided for your convenience to provide
            further information. They do not signify that we endorse the
            website(s). We have no responsibility for the content of the linked
            website(s).
          </ListItem>
          <ListItem>
            You may not create a link to this website from another website or
            document without Sonam Agrotech Pvt Ltd. prior written
            consent.
          </ListItem>
          <ListItem>
            Your use of this website and any dispute arising out of such use of
            the website is subject to the laws of India or other regulatory
            authority.
          </ListItem>
        </UnorderedList>


         <Text>
            "We, as a merchant, shall be under no liability whatsoever in
            respect of any loss or damage arising directly or indirectly out of
            the decline of authorization for any transaction, on account of the
            cardholder having exceeded the preset limit mutually agreed by us
            with our acquiring bank from time to time."
          </Text>

        <OrderedList pl={6}>
         
          <ListItem>
            To ensure that your order reaches you in our standard time (6 to 12
            working days) and in good condition, we will ship through standard
            courier agencies like Delhivery, FedEx, Xpressbees, Gati, Dotzot,
            Ecom Express, and many more. Note: If we ship through postal service,
            it may take more time.
          </ListItem>
         
          <ListItem>
            If you are a new user, our executive will contact you to confirm your
            order. For perfect delivery, our executive will confirm your exact
            delivery address and pin code. If your pin code is not serviceable or
            we are not able to send the material, you have to give us another
            address and pin code.
          </ListItem>
          <ListItem>
            If you believe that the product is not in good condition, or if the
            packaging is tampered with or damaged, before accepting delivery of
            the goods, please refuse to take delivery of the package, click some
            pictures, and write instructions to the courier (like "The package is
            tampered or damaged in courier, so I will not accept the order") and
            send us a mail at <Text as={"b"}> <a href={mailtoLink}>{emailAddress}</a></Text>, mentioning your order
            reference number and attaching pictures. Alternatively, call our
            customer care. We shall make our best efforts to ensure that a
            replacement delivery is made to you at the earliest.
          </ListItem>
          <ListItem>
            Delivery time mentioned on the product, cart page, or website is
            estimated. Actual delivery time is based on the availability of a
            product, weather conditions, and the address where the product is to
            be delivered, as well as the courier company's rules. Note: If your
            address is in an ODA (Out of Delivery Area) location, you will need
            to self-collect the parcel from the courier office. We will provide
            the courier office address and number. Our customer care executive
            will also keep in touch with you.
          </ListItem>
          <ListItem>
            For any issues with our services, you may contact our helpdesk at
            <a href="#" onClick={handlePhoneClick}> <Text as={"b"}>9142429858</Text></a> or mail us at <Text as={"b"}> <a href={mailtoLink}>{emailAddress}</a></Text>.
          </ListItem>
          <ListItem>
            We try our best to get your product delivered that you have purchased
            from our platform. If it is delayed, or not delivered, and any loss
            occurs to you because of it, Sonam Agrotech Pvt Ltd. is not responsible.
          </ListItem>
          <ListItem>
            We at Sonam Agrotech Pvt Ltd. assume that the customer is 18+ and is responsible for
            the usage of any products. If you are under 18, you may only use the
            services with the prior consent of your parent or legal guardian. All
            disputes are subject to Rajkot jurisdiction only.
          </ListItem>
          <Text as={"i"}>
           * The above terms and conditions may change at any time with or without
            prior notice.
          </Text>
        </OrderedList>
      </Stack>
    </Container></Box>
  );
};

export default TermsOfUse;
