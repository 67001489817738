import {
  Box,
  Image,
  Text,
  Select,
  Button,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductQuantityFromCartAsync,
  // deleteProductFromCartAsync,
  selectCart,
} from "../features/Cart/CartSlice";
import { loginUserSelector } from "../features/User/UserSlice";
import { RemoveCartPopup } from "../component/Modal/RemoveCartPopup";
import EmptyCartImage from "../images/shopping-cart.png";
import { HiShoppingCart } from "react-icons/hi";
import { Link } from "react-router-dom";

const CartPage = () => {
  const loginUser = useSelector(loginUserSelector);
  const dispatch = useDispatch();
  const cart = useSelector(selectCart);
  const handleSelectChange = (e, product_id) => {
    const quantity = e.target.value;

    dispatch(
      addProductQuantityFromCartAsync({
        user_id: loginUser?.data?.user_id,
        product_id,
        quantity,
      })
    );
  };
  return (
    <>
      {cart?.data?.products?.length > 0 ? (
        <>
          <Box p={4}>
            <Text
              color={"#404040"}
              fontSize={["20px","32px"]}
              fontWeight={"700"}
              mb={4}
              ml={"3%"}
            >
              Order Summary
            </Text>
            <Box display={"flex"} flexDirection={["column","column","column","column","row"]} justifyContent="space-around">
              <Box>
                {cart?.data?.products?.map((product,i) => (
                  <Box key={i}
                    w={["100%","800px"]}
                    h={["auto","224px"]}
                    p={4}
                    borderRadius={"14px"}
                    background="#F5F5F5"
                    display="flex"
                    alignItems="center"
                    mb={4}
                  >
                    <Link to={`/productdetailpage/${product.product_id}`}>
                      <Image
                        src={product.image}
                        w={"100px"}
                        h={["3/2","100px"]}
                        mr={4}
                        borderRadius="8px"
                        alt="Product Image"
                      />
                    </Link>
                    <Box flex="1">
                      
                      <Text
                        color={"#404040"}
                        fontSize={["10px","16px"]}
                        fontWeight={"500"}
                        mb={2}
                      >
                        {product.productName}
                      </Text>
                      
                      <Box display={"flex"}>
                        <Text
                          color={"#8E8E8E"}
                          fontSize={"16px"}
                          fontWeight={"500"}
                        >
                          SIZE
                        </Text>
                        <Text
                          color={"#404040"}
                          fontSize="16px"
                          fontWeight={"500"}
                        >
                          : 50m
                        </Text>
                      </Box>
                      <Text
                        fontSize={["20px","24px"]}
                        fontWeight={"500"}
                        color="#52771A"
                        mt={"20px"}
                      >
                        ₹ {product.price * product.quantity}
                      </Text>
                    </Box>
                
                    <Box> 
                      <RemoveCartPopup
                        product_id={product.product_id}
                        user_id={loginUser?.data?.user_id}
                        productName =  {product.productName}
                      />

                      <Select
                        mt={10}
                        ml={2}
                        size="sm"
                        w={["100px","150px"]}
                        h={["30px","50px"]}
                        defaultValue={product.quantity}
                        style={{
                          flexShrink: 0,
                          borderRadius: "7px",
                          border: "1px solid #52771A",
                          background: "#F5F5F5",
                          color: "#52771A",
                          textAlign: "center",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                          letterSpacing: "0.48px",
                        }}
                        onChange={(e) =>
                          handleSelectChange(e, product.product_id)
                        }
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M6.71002 9.31002C6.80254 9.21732 6.91242 9.14377 7.0334 9.09359C7.15437 9.04341 7.28405 9.01758 7.41502 9.01758C7.54599 9.01758 7.67567 9.04341 7.79665 9.09359C7.91762 9.14377 8.02751 9.21732 8.12002 9.31002L12 13.19L15.88 9.31002C16.067 9.12305 16.3206 9.018 16.585 9.018C16.8494 9.018 17.103 9.12305 17.29 9.31002C17.477 9.497 17.582 9.7506 17.582 10.015C17.582 10.2794 17.477 10.533 17.29 10.72L12.7 15.31C12.6075 15.4027 12.4976 15.4763 12.3766 15.5265C12.2557 15.5766 12.126 15.6025 11.995 15.6025C11.8641 15.6025 11.7344 15.5766 11.6134 15.5265C11.4924 15.4763 11.3825 15.4027 11.29 15.31L6.70002 10.72C6.32002 10.34 6.32002 9.70002 6.71002 9.31002Z"
                              fill="#52771A"
                            />
                          </svg>
                        }
                      >
                        {/* <option value="1">QTY: {}</option> */}
                        <option value="1" >QTY: 1</option>
                        <option value="2">QTY: 2</option>
                        <option value="3">QTY: 3</option>
                        <option value="4">QTY: 4</option>
                      </Select>
                    </Box>
                  </Box>
                ))}
<Link to={"/products"}>
                <Box
                  w={["100%","800px"]}
                  h="50px"
                  borderRadius={"0px 0px 14px 14px"}
                  background="#F5F5F5"
                  display={"flex"}
                  justifyContent="space-between"
                  p={4}
                  alignItems={"center"}
                >
                  <Text color={"#52771A"} fontSize={["10px","16px"]} fontWeight={"500"}>
                    ADD MORE ITEMS
                  </Text>
                  <AddIcon w={"24px"} h="24px" color={"#52771A"} />
                </Box></Link>
              </Box>
              <Box
                w={["100%","514px"]}
                h="286px"
                borderRadius="14px"
                background="#F5F5F5"
                p={6}
              >
                <Text color="#707070" fontSize="16px" fontWeight="700">
                  Payment Details
                </Text>
                <Box
                  color="#404040"
                  fontSize="16px"
                  fontWeight="500"
                  mt={"20px"}
                >
                  <Box display="flex" justifyContent="space-between">
                    <Text>MRP Total</Text>
                    <Text>₹ {cart?.totalPrice}.00</Text>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Text>Shipping/Delivery Charges</Text>
                    <Text>₹ 7,500.00</Text>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    color="#52771A"
                    mt="30px"
                  >
                    <Text>Total Payable</Text>
                    <Text>₹ {cart?.totalPrice + 7500}.00</Text>
                  </Box>
                </Box>

                <Flex justify="space-between" mt={"30px"}>
                  <Box>
                    <Text color="#8E8E8E" fontSize="10px" fontWeight="700">
                      Total Payable
                    </Text>
                    <Text fontWeight="700">₹ {cart?.totalPrice + 7500}.00</Text>
                  </Box>
                  <Link to={"/addresspage"}>
                  <Box>
                    <Button
                      w={["100%","200px"]}
                      h="50px"
                      borderRadius="7px"
                      backgroundColor="#52771A"
                      color="#FFFFFF"
                      fontSize="16px"
                      fontWeight="700"
                    >
                      PROCEED
                    </Button>
                  </Box></Link>
                </Flex>
              </Box>
            </Box>

            {/* Repeat the above box for other items in the cart */}
          </Box>

          <Box p="20px" backgroundColor={"#FFF7E9;"} height="300px" mb="18%">
            <Heading
              mt={["2px","30px"]}
              color={"#404040"}
              fontSize={["20px","32px"]}
              fontWeight={"700"}
              ml={["5px","35px"]}
              mb={"20px"}
            >
              BEST SELLING PRODUCTS
            </Heading>

            {/* {realetedProduct?.status === 1 && ( */}
            {/* <ProductCarousel data={} /> */}
            {/* )} */}
          </Box>
        </>
      ) : (
        <Box>
          <Box mt={"2%"}>
            <Image src={EmptyCartImage} margin={"auto"} w={["100px","100px","100px","100px","300px"]} h={["100px","100px","100px","100px","300px"]} />
          </Box>
          <Box mt={"2%"}>
            <Heading textAlign={"center"} fontSize={["20px","20px","30px","40px","50px"]}>
              Your cart is currently empty
            </Heading>
            <Text
              textAlign={"center"}
              color="gray"
              fontWeight={500}
              fontSize={["10px","10px","10px","10px","10px","20px"]}
              mt={["5px","20px"]}
            >
              Before proceed to checkout, you must add some products to your
              cart.
            </Text>
            <Text
              textAlign={"center"}
              color="gray"
              fontWeight={500}
              fontSize={["10px","10px","10px","10px","10px","20px"]}
            >
              You will find a lot of interesting products on our "Shop" page.
            </Text>
          </Box>
          <Link to={"/"}>
            <Button
              leftIcon={<HiShoppingCart/>}
              borderRadius="7px"
              height="50px"
              backgroundColor="#52771A"
              color="#F3FFE1"
              textAlign="center"
              fontFamily="Inter"
              fontSize={["20px","24px"]}
              fontWeight="500"
              mt="3%"
              mx="auto"
              mb={"20px"}
              display="block"
              _hover={{ bg: "#405a13" }}
              variant="solid"
            >
              RETURN TO SHOP
            </Button>
          </Link>
        </Box>
      )}
    </>
  );
};

export default CartPage;
