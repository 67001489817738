import axios from "axios";



export function fetchCategory() {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      process.env.REACT_APP_BASE_API+ "/category/getCategory"
    );
    const data = response.data;
    resolve({ data });
  });
}
export function fetchCategoryById(categoryId) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/category/getCategoryById",
      {
        categoryId: categoryId,
      }
    );

    const data = response.data;
    resolve({ data });
  });
}

