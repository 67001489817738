import "./App.css";
import RouterPage from "./RouterPages/RouterPage";
import Navbar from "./component/Navbar/Navbar";
import Footer from "./component/Footer/Footer";
import { Hide } from "@chakra-ui/react";
import { useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";
import { fetchProductAsync } from "./features/Product/ProductSlice";
import { fetchServiceAsync } from "./features/Service/ServiceSlice";
import { useEffect } from "react";
import {
  fetchSelectedAddressAsync,
  fetchUserAsync,
  fetchUserProfileAsync,
  getDeliveryAddressrAsync,
  loginUserSelector,
} from "./features/User/UserSlice";
import BottomNav from "./component/Navbar/BottomNav";
import AdminLogin from "./RouterPages/AdminLogin";

function App() {
  const location = useLocation();
  const loginUser = useSelector(loginUserSelector);
  
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProductAsync(1));
   
    dispatch(fetchUserProfileAsync());
  }, []);

  useEffect(() => {
    if (loginUser?.status === 1) {
      dispatch(fetchUserAsync({ id: loginUser.data.user_id }));
      dispatch(getDeliveryAddressrAsync({ id: loginUser.data.user_id }));
      dispatch(fetchSelectedAddressAsync({ id: loginUser.data.user_id }));
    }
  }, [loginUser]);

  return (
    <>
 {location.pathname !== '/' && <Navbar />} 
      <RouterPage />
     {location.pathname !== '/' &&<Footer />}
      <Hide above="md">
        <BottomNav />
      </Hide>

   
    </>
  );
}

export default App;
