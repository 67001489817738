import { Box, Select, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Pagination from "../../component/Pagination";
import SchemeCard from "./SchemeCard";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGovermentschmeAsync,
  fetchGovermentschmeByStateAsync,
  selectGovermentschme,
} from "../../features/Govermentschme/GovermentschmeSlice";
import GovtPageSkeleton from "../../component/Skeletons/GovtPageSkeleton";
import { states } from "../../utils";
import { useNavigate } from "react-router-dom";

const govtSchemeStyle = {
  main_heading: {
    color: "#404040",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    textAlign: "center",
  },
  main_heading_text: {
    color: "#000",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textAlign: "center",
  },
};

const GovtSchemePage = () => {
  const govermentschme = useSelector(selectGovermentschme);
  const [govermentschmeState, setGovermentschmeState] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading , setLoading] = useState(true);
  const navigate = useNavigate()
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
 
  const dispatch = useDispatch();

  useEffect(() => {
    if (govermentschmeState) {
      setLoading(true)
       let type  ="user"
      dispatch(
        fetchGovermentschmeByStateAsync({ govermentschmeState, currentPage ,type})
      ).then((res)=>{if(res.payload.status === 1){
        setLoading(false)
      }else{
        setLoading(false)
      navigate("/noresult", { state: { message: 'No government schemes available for this state right now.' } })
      }});
    } else {
        dispatch(fetchGovermentschmeAsync(currentPage)).then((res) =>{if(res.payload.status === 1){setLoading(false)}});   
    }
  }, [dispatch,currentPage, govermentschmeState]);

  let totalPages = Math.ceil(
    govermentschme?.data?.totalCount / govermentschme?.data?.itemsPerPage
  );

  return (
    <>
    <Box
      background="linear-gradient(180deg, #F3FFE1 0%, #FBFFF6 100%)"
      padding={["10px","40px"]}
    >
      <Box pt={["0px","30px"]}>
        <Text fontSize={["30px","30px","54px","64px","64px"]} style={govtSchemeStyle.main_heading}>Government Schemes</Text>
        <Text fontSize={["15px","15px","20px","20px","24px"]} style={govtSchemeStyle.main_heading_text}>
          Discover a wide range of government schemes tailored to fit your needs
        </Text>
      </Box>

      <Select
        placeholder="All State"
        width="200px"
        height="50px"
        borderRadius="14px"
        border="1px solid #A0A0A0"
        background=" #F9F9F9"
        color="#8E8E8E"
        fontFamily="Inter"
        fontSize="16px"
        fontStyle="normal"
        fontWeight="500"
        ml={["70px","200px","500px","700px","1200px"]}
        mt={["20px","40px"]}
        mb={["20px","40px"]}
        onChange={(event) => {
          const selectedValue = event.target.value;
          setCurrentPage(1);
          setGovermentschmeState(selectedValue);
        }}
      >
        {states.map((el) => (
          <option key={el} value={el}>
            {el}
          </option>
        ))}
      </Select>
{loading ? (<GovtPageSkeleton/>):(   <Box  display="grid" gridTemplateColumns={["1fr","1fr","1fr","1fr","repeat(2,1fr)"]} gap={["30px","50px" ]}>
        {govermentschme.status === 1 &&
          govermentschme.data.items.map((el,i) => (
            <SchemeCard key={i}
              id={el._id}
              name={el?.govermentschemeName}
              state={el?.statename}
              content={el.content}
              image={el.image}
              govermentschmeState={govermentschmeState}
            />
          ))}
      </Box>)}
      <Box display={"flex"} justifyContent="right">
        <Pagination
          totalPages={totalPages}
          currentPage={govermentschme?.data?.page}
          itemPerPage={govermentschme?.data?.itemsPerPage}
          onPageChange={handlePageChange}
        />
      </Box>
    </Box>

    </>
  );
};

export default GovtSchemePage;
