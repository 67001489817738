import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
 
  fetchSubCategoryByCategoryId,
  fetchSubCategoryById,
  fetchSubCategoryNameByCategoryId,
  fetchSubcategory,
 
} from "./SubategoryAPI";
const initialState = {
  value: 0,
  status: "idle",
  subcategoryData: [],
  adminsubcategoryData: [],
  subcategoryByCategoryName: null,
  subcategoryByCategoryId: null,
  subcategoryById: [],
};

export const fetchSubcategoryAsync = createAsyncThunk(
  "subcategory/fetchSubcategory",
  async () => {
    const response = await fetchSubcategory();

    return response.data;
  }
);
export const fetchSubcategoryByIdAsync = createAsyncThunk(
  "subcategory/fetchSubCategoryById",
  async (subcategoryId) => {
    const response = await fetchSubCategoryById(subcategoryId);

    return response.data;
  }
);

export const fetchSubCategoryNameByCategoryIdAsync = createAsyncThunk(
  "subcategory/fetchSubCategoryNameByCategoryId",
  async (categoryId) => {
    const response = await fetchSubCategoryNameByCategoryId(categoryId);

    return response.data;
  }
);

export const fetchSubCategoryByCategoryIdAsync = createAsyncThunk(
  "subcategory/fetchSubCategoryByCategoryId",
  async (categoryId) => {
    const response = await fetchSubCategoryByCategoryId(categoryId);

    return response.data;
  }
);

export const CategorySlice = createSlice({
  name: "category",
  initialState,

  reducers: {
    increment: (state) => {
      state.value += 1;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchSubcategoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSubcategoryAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.subcategoryData = action.payload;
      })
     
      .addCase(fetchSubcategoryByIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSubcategoryByIdAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.subcategoryById.push(action.payload.data[0]);
      })
    
      
      .addCase(fetchSubCategoryNameByCategoryIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchSubCategoryNameByCategoryIdAsync.fulfilled,
        (state, action) => {
          state.status = "idle";
          state.subcategoryByCategoryName = action.payload;
        }
      )
      .addCase(fetchSubCategoryByCategoryIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSubCategoryByCategoryIdAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.subcategoryByCategoryId = action.payload;
      });
  },
});

// export const { increment } = UserSlice.actions;

export const selectSubcategory = (state) => state.subcategory.subcategoryData;
export const selectSubcategoryByCategoryName = (state) =>
  state.subcategory.subcategoryByCategoryName;
export const selectSubcategoryByCategoryId = (state) =>
  state.subcategory.subcategoryByCategoryId;
export const selectSubcategoryById = (state) =>
  state.subcategory.subcategoryById;

export default CategorySlice.reducer;
