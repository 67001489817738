import React, { useState } from "react";
import {
  Box,
  useBreakpointValue,
} from "@chakra-ui/react";
import Slider from "react-slick";
import ExploreProductCard from "../ExploreProductCard";

export default function ProductCarousel({ data }) {
  const [slider, setSlider] = useState(null);
  const maxSlidesToShow = Math.min(data.length, 4);
  const slidesToShow = useBreakpointValue({  base: 1,
    sm:1,
    md: Math.min(2, maxSlidesToShow),
    lg: Math.min(3, maxSlidesToShow),
    xl: Math.min(4, maxSlidesToShow),
  });
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, background: "green",borderRadius:"50%" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, background: "green",borderRadius:"50%"}}
        onClick={onClick}
      />
    );
  }
  const settings = {
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 3000,
    arrows: true,
    speed: 500,
    slidesToShow: slidesToShow, 
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
   
  };
  return (
    <Box px={["40px","20px"]} position={"relative"} width={"full"} overflow={"hidden"}>
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
    
      <Slider {...settings} ref={(slider) => setSlider(slider)}>
        {data?.map((el,i) => (
          <Box key={i}>
            <ExploreProductCard user_id={el._id} id={el._id} name={el.productName} price={el.productRate} image={el.productImage[0]}/>
      
          </Box>
        ))}
      </Slider>
    </Box>
  );
}
