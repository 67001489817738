import React, { useState } from "react";
import {
  Avatar,
  Box,
  IconButton,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import MyAccountOptions from "./MyAccountOptions";
import PersonalInformation from "./PersonalInformation";
import { useSelector } from "react-redux";
import { userSelector } from "../../features/User/UserSlice";
import DelieveryAddress from "./DelieveryAddress";
import MyOrders from "./MyOrders";
import { MdModeEdit } from "react-icons/md";
import { Link } from "react-router-dom";

const AccountStyle = {
  headingStyle: {
    color: "#404040",
    fontFamily: "Inter",
    fontWeight: 700,
  },
  textStyle: {
    color: "#707070",
    fontFamily: "Inter",
    fontWeight: 300,
  },
  labelStyle: {
    color: "#52771A",
    fontFamily: "Inter",
    fontSize: 13,
    fontWeight: 500,
  },
  inputStyle: {
    width: "300px",
    height: "40px",
    borderRadius: "8px",
    border: "1px solid #A0A0A0",
    color: "#404040",
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 400,
  },
  buttonStyle: {
    width: "300px",
    height: "40px",
    flexShrink: 0,
    borderRadius: "8px",
    background: "#52771A",
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
  iconStyle: {
    width: "13px",
    height: "13px",

    fill: "#FFF",
  },
};

const AccountPage = () => {
  const userData = useSelector(userSelector);
  const isMobile = useBreakpointValue({ base: true,lg:false });
  const [option, setOption] = useState(1);
  const handleOption = (opt) => {
    setOption(opt);
  };
  return (
    <Box backgroundColor="#F5F5F5" h={"auto"} w="100%" mb={"20px"}>
      <Box  w={["100%","100%","100%","100%", "1118px"]} height={"auto"} margin="auto" mt={"20px"}>
        {!isMobile && (
          <Box>
            <Text color={"#404040"} fontSize="24px" fontWeight={"700"}>
              Your Account
            </Text>
          </Box>
        )}
        <Box display="flex" justifyContent={["space-around","space-around","space-around","space-around","space-between"]} gap="30px">
          <Box >
            <Box
              boxShadow="lg"
              width={["100%","100%","100%","100%","400px"]}
              height={["100px","150px"]}
              borderRadius="14px"
              background="#FFFFFF"
              display="flex"
              gap={"10px"}
              p={[2,4]}
              alignItems="center"
              mt={["0","20px"]}
              
            >
              <Avatar
                bg="#52771A"
                w={["50px", "100px"]}
                h={["50px", "100px"]}
                mx={4}
              />
              <VStack   spacing={[0,2]} align="left">
                <Text
                  fontSize={["20px", "24px"]}
                  style={AccountStyle.headingStyle}
                >
                  {userData?.data?.fullName}
                </Text>
                <Text fontSize={["10px","13px"]} style={AccountStyle.textStyle} >
                  {userData?.data?.email}
                </Text>
                <Text fontSize={["10px","13px"]} style={AccountStyle.textStyle}>
                  +{userData?.data?.mobileNo}
                </Text>
              </VStack>
              {isMobile && (
                <Box   ml={"60px"}>
                <Link to={"/editProfileForm"} >
                  <IconButton
                    isRound={true}
                    variant="solid"
                    aria-label="Done"
                    fontSize="20px"
                    icon={<MdModeEdit />}
                  />
                </Link></Box>
              )}
            </Box>
            <Box mt={"40px"}>
              <MyAccountOptions handleOption={handleOption} />
            </Box>
          </Box>
          {!isMobile && (
            <Box mt={"20px"}>
              {option === 1 ? (
                <PersonalInformation />
              ) : option === 2 ? (
                <DelieveryAddress
                  id={userData?.data?._id}
                  address={userData?.data?.deliveryAddress}
                />
              ) : option === 3 ? (
                <MyOrders />
              ) : null}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AccountPage;
