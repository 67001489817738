import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addProductQuantityFromCart, createCart, deleteProductFromCart, fetchUserCart } from "./CartAPI";

const initialState = {
  value: 0,
  status: "idle",
  cartData: null,
  allreadyAddedCart : {}
};



export const createCartAsync = createAsyncThunk(
  "cart/createCart",
  async ({ user_id, product }) => {
    const response = await createCart(user_id, product);

    return response.data;
  }
);
export const allreadyAddedCartAsync = createAsyncThunk(
  "cart/allreadyAddedCart",
  async (data ) => {
   
    return data;
  }
);
export const fetchUserCartAsync = createAsyncThunk(
  "cart/fetchUserCart",
  async (user_id) => {
    const response = await fetchUserCart(user_id);

    return response.data;
  }
);

export const deleteProductFromCartAsync = createAsyncThunk(
  "cart/deleteCartById",
    async ({ user_id, product_id }) => {
    const response = await deleteProductFromCart(user_id, product_id);
    return response.data;
  }
);
export const addProductQuantityFromCartAsync = createAsyncThunk(
  "cart/addProductQuantityFromCart",
    async ({ user_id, product_id ,quantity}) => {
    const response = await addProductQuantityFromCart(user_id, product_id,quantity);
    return response.data;
  }
);






export const CartSlice = createSlice({
  name: "cart",
  initialState,

  reducers: {
    increment: (state) => {
      state.value += 1;
    },
  },

  extraReducers: (builder) => {
    builder

      .addCase(fetchUserCartAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserCartAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.cartData = action.payload;
      })

      .addCase(deleteProductFromCartAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteProductFromCartAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.cartData = action.payload;
      })
      .addCase(createCartAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCartAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.cartData = action.payload;
      })
      .addCase(allreadyAddedCartAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(allreadyAddedCartAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.allreadyAddedCart = action.payload;
      })
      .addCase(addProductQuantityFromCartAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addProductQuantityFromCartAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.cartData = action.payload;
      });
      
  },
});

// export const { increment } = UserSlice.actions;

export const selectCart = (state) => state.cart.cartData; 
export const selectallreadyAddedCart = (state) => state.cart.allreadyAddedCart; 


export default CartSlice.reducer;
