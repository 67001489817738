import React from "react";
import { Box, Text, Input, Button, useBreakpointValue } from "@chakra-ui/react";
import { MdModeEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { userSelector } from "../../features/User/UserSlice";
const AccountStyle = {
  headingStyle: {
    color: "#404040",
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 700,
  },
  textStyle: {
    color: "#707070",
    fontFamily: "Inter",
    fontSize: "13px",
    fontWeight: 300,
  },
  labelStyle: {
    color: "#52771A",
    fontFamily: "Inter",
    fontSize: 13,
    fontWeight: 500,
  },
  inputStyle: {
    width: "300px",
    height: "40px",
    borderRadius: "8px",
    border: "1px solid #A0A0A0",
    color: "#404040",
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 400,
  },
  buttonStyle: {
    width: "300px",
    height: "40px",
    flexShrink: 0,
    borderRadius: "8px",
    background: "#52771A",
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
  iconStyle: {
    width: "13px",
    height: "13px",

    fill: "#FFF",
  },
};
const PersonalInformation = () => {
  const userData = useSelector(userSelector);
  const isMobile = useBreakpointValue({ base: true, sm: false });
  return (
    <Box
      display={"flex"}
      borderRadius="14px"
      bg="#FFF"
      w="688px"
      justifyContent="space-between" // Create space between the two inner boxes
      padding="20px" // Add padding to create space around elements
    >
      <Box flex="1" marginRight="20px">
        {" "}
        {/* Use flex to divide the space */}
        <Text
          style={{
            color: "#707070",
            fontFamily: "Inter",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
            marginBottom: "15px", // Add margin bottom for spacing
          }}
        >
          LOGIN INFORMATION
        </Text>
        <Text style={AccountStyle.labelStyle} marginBottom="10px">
          EMAIL
        </Text>
        <Input
          isReadOnly
          value={userData?.data?.email}
          style={{ ...AccountStyle.inputStyle, marginBottom: "15px" }}
        />{" "}
        {/* Add margin bottom for spacing */}
        <Text style={AccountStyle.labelStyle} marginBottom="10px">
          MOBILE NUMBER
        </Text>
        <Input
          isReadOnly
          value={userData?.data?.mobileNo}
          style={{ ...AccountStyle.inputStyle, marginBottom: "15px" }}
        />{" "}
        {/* Add margin bottom for spacing */}
      </Box>
      <Box flex="1">
        <Text
          style={{
            color: "#707070",
            fontFamily: "Inter",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
            marginBottom: "15px", // Add margin bottom for spacing
          }}
        >
          PERSONAL INFORMATION
        </Text>
        <Text style={AccountStyle.labelStyle} marginBottom="10px">
          FULL NAME
        </Text>
        <Input
          isReadOnly
          value={userData?.data?.fullName}
          style={{ ...AccountStyle.inputStyle, marginBottom: "15px" }}
        />{" "}
        {/* Add margin bottom for spacing */}
        <Text style={AccountStyle.labelStyle} marginBottom="10px">
          STATE
        </Text>
        <Input
          isReadOnly
          value={userData?.data?.state}
          style={{ ...AccountStyle.inputStyle, marginBottom: "15px" }}
        />{" "}
        {/* Add margin bottom for spacing */}
        {!isMobile && (
  <Link to={"/editProfileForm"}>
  {" "}
  <Button
    leftIcon={<MdModeEdit style={AccountStyle.iconStyle} />}
    style={AccountStyle.buttonStyle}
    marginTop="10px"
  >
    EDIT PROFILE
  </Button>
</Link>

        )}
      
      </Box>
    </Box>
  );
};

export default PersonalInformation;
