import { AddIcon, EditIcon } from "@chakra-ui/icons";
import { useForm } from "react-hook-form";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Input,
  Text,
  Box,
  IconButton,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  addDeliveryAddressrAsync,
  updateDeliveryAddressAsync,
} from "../../features/User/UserSlice";

export function AddAddressForm({ user_id, address_id, addressData }) {
  const {
    register,
    handleSubmit,
    setValue
  } = useForm();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const isMobile = useBreakpointValue({ base: true, sm: false });
  const toast = useToast();
  useEffect(() => {
    setValue("pincode", addressData?.pincode);
    setValue("city", addressData?.city);
    setValue("state", addressData?.state);
    setValue("fullname", addressData?.fullName);
    setValue("address", addressData?.address);
    setValue("landmark", addressData?.landmark);
    setValue("mobileNo", addressData?.mobileNo);
    setValue("altmobileNo", addressData?.altmobileNo);
  }, [address_id]);

  const onSubmit = (data) => {
    if (address_id) {
      dispatch(updateDeliveryAddressAsync({ user_id, address_id, data })).then(
        (res) => {
          toast({
            title: "Edit Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      )
      onClose()
    } else {
      dispatch(addDeliveryAddressrAsync({ id: user_id, data })).then(
        (res) => {
          toast({
            title: "New Address Added!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      )
      onClose();
      const fieldsToClear = ["pincode", "city", "state", "fullname", "address", "landmark", "mobileNo", "altmobileNo"];
      fieldsToClear.forEach(field => setValue(field, ""));
    }
  };

  return (
    <>
      {address_id ? (
         <IconButton
         ref={btnRef}
         onClick={onOpen}
         icon={<EditIcon />}
         size="sm"
         color={"#ffffff"}
         backgroundColor={"#52771a"}
        
       />
      ) : (
        <Button 
          leftIcon={!isMobile && <AddIcon w="24px" h="24px" color={"#52771A"} />}
          ref={btnRef}
          onClick={onOpen}
          fontSize="16px"
          fontWeight={"300"}
          color={isMobile ? "#FFFFFF":"#707070"}
          borderRadius={"7px"}
          border={isMobile ?"":"1px solid #151B39"}
          w={["100%","275px"]}
          h={["40px","50px"]}
          display="flex"
          mt={[3,0]}
          justifyContent={"space-evenly"}
          backgroundColor={isMobile ? "#52771A":"#FFFFFF"}
        >
          Add New Address
        </Button>
      )}

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="sm"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          {addressData ? (
            <DrawerHeader color={"#404040"} fontSize="14px" fontWeight="700">
              EDIT ADDRESS
            </DrawerHeader>
          ) : (
            <DrawerHeader color={"#404040"} fontSize="14px" fontWeight="700">
              ADD ADDRESS
            </DrawerHeader>
          )}

          <DrawerBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Text color={"#52771A"} fontSize="13px" fontWeight={"500"} mb={2}>
                PIN CODE
              </Text>
              <Input
                mb={2}
                width={"380px"}
                h="40px"
                borderRadius={"8px"}
                border="1px solid #A0A0A0"
                id="pincode"
                {...register("pincode", {
                  required: "pincode must be required",
                })}
              />
              <Box display={"flex"} w="380px" gap={"10px"} mb={2}>
                <Box>
                  <Text
                    color={"#52771A"}
                    fontSize="13px"
                    fontWeight={"500"}
                    mb={2}
                  >
                    CITY
                  </Text>
                  <Input
                    mb={2}
                    h="40px"
                    borderRadius={"8px"}
                    border="1px solid #A0A0A0"
                    id="city"
                    {...register("city", {
                      required: "City name must be required",
                    })}
                  />
                </Box>
                <Box>
                  <Text
                    color={"#52771A"}
                    fontSize="13px"
                    fontWeight={"500"}
                    mb={2}
                  >
                    STATE
                  </Text>
                  <Input
                    mb={2}
                    h="40px"
                    borderRadius={"8px"}
                    border="1px solid #A0A0A0"
                    id="state"
                    {...register("state", {
                      required: "State name must be required",
                    })}
                  />
                </Box>
              </Box>

              <Text color={"#52771A"} fontSize="13px" fontWeight={"500"} mb={2}>
                FULL NAME
              </Text>
              <Input
                mb={2}
                width={"380px"}
                h="40px"
                borderRadius={"8px"}
                border="1px solid #A0A0A0"
                id="fullname"
                {...register("fullname", {
                  required: "Name must be required",
                })}
              />
              <Text color={"#52771A"} fontSize="13px" fontWeight={"500"} mb={2}>
                ADDRESS
              </Text>
              <Input
                mb={2}
                width={"380px"}
                h="40px"
                borderRadius={"8px"}
                border="1px solid #A0A0A0"
                id="address"
                {...register("address", {
                  required: "Address must be required",
                })}
              />
              <Text color={"#52771A"} fontSize="13px" fontWeight={"500"} mb={2}>
                LANDMARK
              </Text>
              <Input
                mb={2}
                width={"380px"}
                h="40px"
                borderRadius={"8px"}
                border="1px solid #A0A0A0"
                id="landmark"
                {...register("landmark", {
                  required: "Landmark must be required",
                })}
              />
              <Text mb={2} color={"#52771A"} fontSize="13px" fontWeight={"500"}>
                PHONE NUMBER
              </Text>
              <Input
                mb={2}
                width={"380px"}
                h="40px"
                borderRadius={"8px"}
                border="1px solid #A0A0A0"
                id="mobileNo"
                {...register("mobileNo", {
                  required: "Phone Nnumber must be required",
                })}
              />
              <Text mb={2} color={"#52771A"} fontSize="13px" fontWeight={"500"}>
                {" "}
                ALTERNATIVE PHONE NUMBER
              </Text>
              <Input
                mb={2}
                width={"380px"}
                h="40px"
                borderRadius={"8px"}
                border="1px solid #A0A0A0"
                id="altmobileNo"
                {...register("altmobileNo", {
                  required: "Alternative phone number must be required",
                })}
              />
              <Button
                mt={4}
                type="submit"
                w={"380px"}
                h="40px"
                borderRadius={"8px"}
                backgroundColor="#52771A"
                color={"#FFFFFF"}
                fontSize="13px"
                fontWeight={"500"}
              >
                SAVE ADDRESS
              </Button>
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
