import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import VisionImage from "../images/VisionAboutPage.png";
import MissionIcon from "../images/MissionIcon.png";
import pallavi from "../images/PallaviSuit.png";
import image2 from "../images/image2.png";

const About = () => {
  return (
    <>
      <Box
        padding={["10px", "40px"]}
        background="linear-gradient(180deg, #F3FFE1 0%, #FBFFF6 100%)"
        textAlign={"justify"}
      >
        <Box
          display={"grid"}
          gridTemplateColumns={[
            "1fr",
            "1fr",
            "1fr",
            "repeat(2,1fr)",
            "repeat(2,1fr)",
          ]}
          gap="40px"
        >
          <Box>
            <Text
              color={"#404040"}
              fontFamily="Inter"
              fontSize={["20px", "32px", "42px", "52px", "52px"]}
              fontWeight="700"
            >
              About Our Company{" "}
            </Text>
            <Text
              fontSize={["20px", "32px", "42px", "52px", "52px"]}
              color="#52771A"
              fontFamily="Inter"
              // fontSize={"52px"}
              fontWeight="700"
            >
              Sonam
            </Text>

            <Text
              color="#000"
              fontFamily="Inter"
              fontSize={["10px", "24px"]}
              fontWeight="400"
              width={["auto", "auto", "auto", "598px", "598px"]}
              mt={["10px", "33px"]}
            >
              We’re here to revolutionize the farming industry using the modern
              technology and tools.
            </Text>
          </Box>
          <Box
            width={"100%"}
            height={["200px", "366px"]}
            backgroundColor={"black"}
          >
            <Image src="" />
          </Box>
        </Box>
        <Box
          backgroundColor={"black"}
          width="100%"
          height={["200px", "368px"]}
          marginTop={["10px", "30px"]}
        >
          <Image src="" />
        </Box>
        <Box mt={"25px"}>
          <Text fontSize={["10px", "16px"]}>
            em Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum. Why do we use it? It is
            a long established fact that a reader will be distracted by the
            readable content of a page when looking at its layout. The point of
            using Lorem Ipsum is that it has a more-or-less normal distribution
            of letters, as opposed to using 'Content here, content here', making
            it look like readable English. Many desktop publishing packages and
            web page editors now use Lorem Ipsum as their default model text,
            and a search for 'lorem ipsum' will uncover many web sites still in
            their infancy. Various versions have evolved over the years,
            sometimes by accident, sometimes on purpose (injected humour and the
            like). Where does it come from? Contrary to popular belief, Lorem
            Ipsum is not simply random text. It has roots in a piece of
            classical Latin literature from 45 BC, making it over 2000 years
            old. Richard McClintock, a Latin professor at Hampden-Sydney College
            in Virginia, looked up one of the more obscure Latin words,
            consectetur, from a Lorem Ipsum passage, and going through the cites
            of the word in classical literature, discovered the undoubtable
            source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de
            Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by
            Cicero, written in 45 BC. This book is a treatise on the theory of
            ethics, very popular during the Renaissance. The first line of Lorem
            Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section
            1.10.32.
          </Text>
        </Box>
        <Box
          p={"20px"}
          display={"flex"}
          flexDirection={["column", "column", "column", "row", "row"]}
          justifyContent="space-around"
          mt={"25px"}
          width={"100%"}
          height={["auto", "auto", "auto", "auto", "435px"]}
          borderRadius={"24px"}
          backgroundColor="#ECFFCF"
          boxShadow={" 0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
        >
          <Box>
            <Text
              color={"#404040"}
              fontFamily="Inter"
              fontSize={["20px", "36px"]}
              fontWeight="700"
            >
              Vision
            </Text>
            <Image
              src={VisionImage}
              w={["100%", "336px"]}
              h={["200px", "336px"]}
            />
          </Box>
          <Box w={["auto", "auto", "auto", "auto", "830px"]}>
            <Text
              color={"#000000"}
              fontSize={["10px", "16px"]}
              fontWeight={"400"}
            >
              Loreum Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Why do we use it? It is a long established fact that
              a reader will be distracted by the readable content of a page when
              looking at its layout. The point of using Lorem Ipsum is that it
              has a more-or-less normal distribution of letters, as opposed to
              using 'Content here, content here', making it look like readable
              English. Many desktop publishing packages and web page editors now
              use Lorem Ipsum as their default model text, and a search for
              'lorem ipsum' will uncover many web sites still in their infancy.
              Various versions have evolved over the years, sometimes by
              accident, sometimes on purpose (injected humour and the like).l
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English. Many desktop
              publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for 'lorem ipsum' will
              uncover many web sites still in their infancy. Various versions
              have evolved over the years, sometimes by accident, sometimes on
              purpose (injected humour and the like).
            </Text>
          </Box>
        </Box>

        <Box mt={"30px"}>
          <Text
            color={"#404040"}
            fontSize={["20px", "36px"]}
            fontWeight={"700"}
            textAlign="center"
          >
            Mission
          </Text>
          <Text
            color={"#000000"}
            textAlign="center"
            fontSize={["10px", "16px"]}
            fontWeight="400"
          >
            This is a sample text for mission
          </Text>
          <Box
            display="grid"
            gridTemplateColumns={[
              "1fr",
              "1fr",
              "repeat(2,1fr)",
              "repeat(2,1fr)",
              "repeat(3,1fr)",
            ]}
            gap="12px"
            mt={"20px"}
          >
            <Box
              display={"flex"}
              flexDirection="column"
              justifyContent={"center"}
              alignItems="center"
              gap={"10px"}
              h="auto"
              borderRadius={"24px"}
              backgroundColor="rgba(3, 54, 255, 0.35)"
              boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
            >
              <Image src={MissionIcon} mt="10px" w={["15%", "auto"]} />
              <Text
                color={"#404040"}
                fontSize={["16px", "28px"]}
                fontWeight={"700"}
              >
                Mission 1
              </Text>
              <Box
                w={["auto", "auto", "auto", "auto", "385px"]}
                mb="10px"
                p={2}
              >
                <Text
                  color={"#000000"}
                  fontSize={["10px", "16px"]}
                  fontWeight={"400"}
                >
                  em Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy
                  text ever since the 1500s, when an unknown printer took a
                  galley of type and scrambled it to make a type specimen book.
                  It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged.
                  It was popularised in the 1960s with the release of{" "}
                </Text>
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection="column"
              justifyContent={"center"}
              alignItems="center"
              gap={"10px"}
              h="auto"
              borderRadius={"24px"}
              backgroundColor="rgba(255, 100, 26, 0.31)"
              boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
            >
              {" "}
              <Image src={MissionIcon} mt="10px" w={["15%", "auto"]} />
              <Text
                color={"#404040"}
                fontSize={["16px", "28px"]}
                fontWeight={"700"}
              >
                Mission 2
              </Text>
              <Box
                p={2}
                w={["auto", "auto", "auto", "auto", "385px"]}
                mb="10px"
              >
                <Text
                  color={"#000000"}
                  fontSize={["10px", "16px"]}
                  fontWeight={"400"}
                >
                  em Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy
                  text ever since the 1500s, when an unknown printer took a
                  galley of type and scrambled it to make a type specimen book.
                  It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged.
                  It was popularised in the 1960s with the release of{" "}
                </Text>
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection="column"
              justifyContent={"center"}
              alignItems="center"
              gap={"10px"}
              h="auto"
              borderRadius={"24px"}
              backgroundColor="rgba(78, 203, 113, 0.35)"
              boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
            >
              {" "}
              <Image src={MissionIcon} mt="10px" w={["15%", "auto"]} />
              <Text
                color={"#404040"}
                fontSize={["16px", "28px"]}
                fontWeight={"700"}
              >
                Mission 3
              </Text>
              <Box
                p={2}
                w={["auto", "auto", "auto", "auto", "385px"]}
                mb="10px"
              >
                <Text
                  color={"#000000"}
                  fontSize={["10px", "16px"]}
                  fontWeight={"400"}
                >
                  em Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy
                  text ever since the 1500s, when an unknown printer took a
                  galley of type and scrambled it to make a type specimen book.
                  It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged.
                  It was popularised in the 1960s with the release of{" "}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        w={"100%"}
        h={["auto", "auto", "auto", "auto", "830px"]}
        background="#404040"
      >
        <Box ml={"6%"} mt={["10px","30px"]} pt={["10px","40px"]}>
          <Text color={"#FFFFFF"} fontSize={["20px","36px"]} fontWeight={"700"}>
            Meet our
          </Text>
          <Text color={"#FFFFFF"} fontSize={["30px","48px"]} fontWeight={"700"}>
            Founders
          </Text>
        </Box>
        <Box
          display={"grid"}
          gridTemplateColumns={[
            "repeat(2,1fr)",
            "auto",
            "auto",
            "auto",
            "repeat(2,629px)",
          ]}
          justifyContent="space-around" 
          p={["10px","40px"]} 
        >
          <Box  backgroundColor={"#D9D9D9"} >
            <Box>
            <Image 
              src={image2}
              w={"auto"}
              m={"auto"}
              // w={["100%","629px"]}
              h={["50%", "419px"]}
            /></Box>
            <Box p={["10px","20px"]} >
              <Text color={"#000000"} fontSize={["20px","32px"]} fontWeight={"700"}>
                Ganesh
              </Text>
              <Text color={"#000000"} fontSize={["10px","22px"]} fontWeight={"700"}>
                CEO
              </Text>
            </Box>
          </Box>
          <Box backgroundColor={"#D9D9D9"}>
            <Box>
            <Image src={pallavi} m={"auto"} w="auto" h={["50%","419px"]} />
            </Box>
            <Box p={["10px","20px"]}>
              {" "}
              <Text color={"#000000"} fontSize={["20px","32px"]} fontWeight={"700"}>
                Ramesh
              </Text>
              <Text color={"#000000"} fontSize={["10px","22px"]} fontWeight={"700"}>
                CTO
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default About;
