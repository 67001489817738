import { Box, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import star from "../../images/solar_star-bold-duotone.png";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchServiceAsync,
  selectService,
} from "../../features/Service/ServiceSlice";
import ServiceBox from "./ServiceBox";
import ServicePageSkeleton from "../../component/Skeletons/ServicePageSkeleton";

const Service = () => {
  const service = useSelector(selectService);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (service.length === 0) {
      dispatch(fetchServiceAsync()).then((res) => {
        if (res.payload.status === 1) {
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  }, [dispatch]);
  return (
    <>
      <Box bg={"linear-gradient(180deg, #f3ffe1 0%, #fff 100%)"}>
        <Box marginBottom={["0px", "100px"]} pt="40px">
          <Box display={"flex"} justifyContent="center" gap={"40px"}>
            <Image
              src={star}
              alt="bigstar"
              width={["20px", "40px", "40px", "50px", "50px"]}
              height={["20px", "40px", "40px", "50px", "50px"]}
            />
            <Image
              src={star}
              alt="smallstar"
              width={["15px", "15px", "20px", "24px", "24px"]}
              height={["15px", "15px", "20px", "24px", "24px"]}
              mt={[0, 10, 10, 20, 20]}
            ></Image>
            <Text
              fontSize={["20px", "20px", "60px", "86px", "86px"]}
              color={"#404040"}
              textAlign={"center"}
              fontWeight={700}
            >
              Our Services
            </Text>
            <Image
              src={star}
              alt="smallstar"
              width={["15px", "15px", "20px", "24px", "24px"]}
              height={["15px", "15px", "20px", "24px", "24px"]}
              mt={[0, 10, 10, 20, 20]}
            ></Image>
            <Image
              src={star}
              alt="bigstar"
              width={["20px", "40px", "40px", "50px", "50px"]}
              height={["20px", "40px", "40px", "50px", "50px"]}
            />
          </Box>
          <Text
            color={"#000"}
            fontWeight={400}
            textAlign={"center"}
            mt={["2px", "10px", "20px", "20px", "20px"]}
            fontSize={["10px", "10px", "20px", "24px", "24px"]}
          >
            Discover a wide range of exceptional services tailored to meet your
            needs
          </Text>
        </Box>
        {loading ? (
          <ServicePageSkeleton />
        ) : (
          <Box p={[2, 0]}>
            {service.status === 1 &&
              service.data.map((el) => (
                <ServiceBox
                  key={el._id}
                  id={el._id}
                  name={el?.serviceName}
                  content={el.content}
                  vedioLink={el?.vedioLink}
                />
              ))}
          </Box>
        )}
      </Box>
    </>
  );
};

export default Service;
