import React, { useState } from "react";
import { HStack, Image, InputRightElement, PinInput, PinInputField, useDisclosure } from "@chakra-ui/react";
import "react-phone-input-2/lib/style.css";
import indianFlagImage from "../../images/IndianFlag.png";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Text,
  Input,
  Flex,InputLeftAddon,
   InputGroup
} from "@chakra-ui/react";
import lock_image from "../../images/mdi_password.png";
import { toast, Toaster } from "react-hot-toast";

import { CgSpinner } from "react-icons/cg";
import { useDispatch } from "react-redux";
import {
  checkMobilNoAsync,
  forgetPasswordAsync,
  otpVerifyUserAsync,
  sendOtpUserAsync,
} from "../../features/User/UserSlice";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
function NewPasswordModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [step, setStep] = useState(1);
  const [mobileNo, SetMobileNo] = useState("");
  const [password, SetPassword] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [otp, setOtp] = useState("");
  const handleChange = (index, value) => {
    const newOtp = otp.slice(0, index) + value + otp.slice(index + 1);
    setOtp(newOtp);
  };
  const onOTPVerify =  async(mobile, otp)=>  {
    const data = {mobileNo :mobile,password};
    dispatch(otpVerifyUserAsync({ mobile, otp })).then((res) => {
      if (res?.payload.status === 1) {
        dispatch(forgetPasswordAsync(data)).then((res) => {
          if (res?.payload.status === 1) {
            toast.success(res?.payload?.message);
          }else{
            toast.error(res?.payload?.message);
          }
        });
      } else {
        toast.error(res?.payload?.message);
      }
    });
  }
  const sendOtp = async () =>{
   dispatch(sendOtpUserAsync(mobileNo)).then((res) =>{if(res?.payload?.status === 1){
    toast.success(res?.payload?.message);
  } else {
    toast.error(res?.payload?.message);
  
   }})
  }

  const handleContinue = async () => {
    try {
      if (step === 1) {
        if (mobileNo.length >= 10 && password.length >= 6) {
          const response = await dispatch(checkMobilNoAsync(mobileNo));

          if (response?.payload?.status === 0) {
            sendOtp();
            setStep((prevStep) => prevStep + 1);
          } else {
            toast.error("This phone number is not registered!");
          }
        } else if (mobileNo.length < 10) {
          toast.error("Please enter a valid phone number.");
        } else {
          toast.error("Password must be at least 6 characters long.");
        }
      } else if (step === 2) {
        onOTPVerify(mobileNo, otp);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleStepClick = async (stepNumber) => {
    setStep(stepNumber);
  };
  return (
    <>
      <Text
        onClick={onOpen}
        color={"#0336ff"}
        fontSize={"16px"}
        fontWeight={500}
        mt={"10px"}
        textAlign={"right"}
      >
        Forgot Password?
      </Text>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          style={{
            backdropFilter: "blur(2px)", // Apply the backdrop blur effect
          }}
        />
        <ModalContent
         width={["90%", "700px"]}
         height={["auto", "550px"]}
        borderRadius="28px"
        background="#fff"
        padding={[2, 10]}
        >
          <ModalCloseButton
            backgroundColor="#8E8E8E"
            borderRadius="50%"
            color="white"
          />
          <ModalBody>
            <div id="recaptcha-container"></div>

            {/* Progress Bar */}
            <Flex
              width="320px"
              height="15px"
              gap={"10px"}
              borderRadius="50px"
              mt={{base:"8",lg:"4"}}
            >
              {/* Step 1 */}
              <Box
                onClick={() => handleStepClick(1)}
                width="50%"
                height="100%"
                borderRadius="50px"
                background="#52771A"
              />
              {/* Step 2 */}
              <Box
                onClick={() => handleStepClick(2)}
                width="50%"
                height="100%"
                borderRadius="50px"
                background={step >= 2 ? "#52771A" : "#F3FFE1"}
              />
            </Flex>
            {step === 1 && (
              <>
                {step === 1 && <Toaster toastOptions={{ duration: 4000 }} />}
                <Box display={"flex"} gap="15px">
                  <Text
                    color="#404040"
                    fontFamily="Inter"
                    fontSize="32px"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="1.5"
                    mt="4"
                  >
                    New Password
                  </Text>
                  <Image
                    src={lock_image}
                    alt="lock_image"
                    width={"24px"}
                    height="24px"
                    mt={7}
                  />
                </Box>

                <Text id="normal_text">Please enter the given credentials</Text>

                <Text
                  color="#404040"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  mt="4"
                >
                  Mobile No
                </Text>
                {/* <PhoneInput
                  country={"in"}
                  value={mobileNo}
                  onChange={SetMobileNo}
                /> */}

                <InputGroup>
                  <InputLeftAddon>
                    <Image
                      src={indianFlagImage}
                      borderRadius="full"
                      boxSize="25px"
                      alt="+91"
                    />
                  </InputLeftAddon>
                  <Input
                    type="number"
                    placeholder="phone number"
                    value={mobileNo}
                    onChange={(event) => SetMobileNo(event.target.value)}
                  />
                </InputGroup>

                <Text
                  color="#404040"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  mt="4"
                >
                  Enter New Password
                </Text>
                {/* <Input
                  type="password"
                  mt="2"
                  focusBorderColor="#0336FF"
                  onChange={(e) => SetPassword(e.target.value)}
                /> */}

<InputGroup size="md">
                <Input
                  onChange={(e) => SetPassword(e.target.value)}
                  focusBorderColor="#0336FF"
                  w={"100%"}
                  pr="4.5rem"
                  type={show ? "text" : "password"}
                  placeholder="Enter password"
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClick}>
                    {show ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              </>
            )}

            {step === 2 && (
              <>
                {step === 2 && <Toaster toastOptions={{ duration: 4000 }} />}

                <Text
                  color="#404040"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  mt="4"
                >
                  Enter 6 Digit OTP
                </Text>
                <HStack mt={5}>
      <PinInput>
        {Array.from({ length: 6 }).map((_, index) => (
          <PinInputField
            key={index}
            value={otp[index] || ""}
            onChange={(e) => handleChange(index, e.target.value)}
          />
        ))}
      </PinInput>
    </HStack>
                {/* <OtpInput
                  value={otp}
                  onChange={setOtp}
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  autoFocus
                ></OtpInput> */}
              </>
            )}
            <Button bg={"#52771A"} borderRadius={"14px"} w={"full"} mt={"30px"} color={"#f3ffe1"} fontFamily={"Inter"} fontSize={"16px"} fontWeight={500} onClick={handleContinue}>
              {loading && <CgSpinner size={20} className="mt-1 animate-spin" />}
              <span>
                {step === 2 ? "Verify OTP" : step === 1 ? "Send OTP" : null}
              </span>
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default NewPasswordModal;
