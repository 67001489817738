import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchService,
 
} from "./ServiceAPI";

const initialState = {
  value: 0,
  status: "idle",
  serviceData: [],
};


export const fetchServiceAsync = createAsyncThunk(
  "service/fetchService",
  async () => {
    const response = await fetchService();

    return response.data;
  }
);

export const ServiceSlice = createSlice({
  name: "service",
  initialState,

  reducers: {
    increment: (state) => {
      state.value += 1;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchServiceAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchServiceAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.serviceData = action.payload;
      })
     
  },
});

// export const { increment } = UserSlice.actions;

export const selectService = (state) => state.service.serviceData;

export default ServiceSlice.reducer;
