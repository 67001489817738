import React, { useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";
import { BiArrowBack } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateUserAsync, userSelector } from "../../features/User/UserSlice";
import { useForm } from "react-hook-form";
import { states } from "../../utils";
function EditProfileForm() {
  const userData = useSelector(userSelector);
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue } = useForm();
  const toast = useToast();
const navigate = useNavigate();
  useEffect(() => {
    setValue("fullName", userData?.data?.fullName);
    setValue("pincode", userData?.data?.pincode);
    setValue("email", userData?.data?.email);
    setValue("firstName", userData?.data?.firstName);
  }, [userData?.data]);
  const onSubmit = (UserData) => {
    dispatch(updateUserAsync({ UserData, userId: userData.data._id })).then(
      (res) => {
        toast({
          title: "Edited Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    ).then(()=>navigate("/myaccount"))
  };
  return (
    <Box
      w={["100%", "100%", "100%", "100%", "1118px"]}
      h="auto"
      borderRadius={"14px"}
      background="#FFFFFF"
      margin={"auto"}
      mb={["2%", "2%", "2%", "2%", "10%"]}
    >
      <Flex
        alignItems="center"
        mb={[0, 0, 0, 0, 4]}
        mt={["5px", "20px"]}
        ml={[5, 5, 5, 5, 0]}
      >
        <Link to={"/myaccount"}>
          <BiArrowBack w={"30px"} h="30px" />
        </Link>
        <Heading color="#404040" ml={[5, 2]} fontSize={["20px", "30px"]}>
          Edit Profile
        </Heading>
      </Flex>
      {userData?.data && (
        <Box
          boxShadow={["none", "none", "none", "none", "lg"]}
          p={[5, 5, 5, 5, 2]}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              templateColumns={["1fr", "1fr", "1fr", "1fr", "repeat(2, 1fr)"]}
              gap={6}
            >
              <GridItem colSpan={1}>
                <FormControl mt={["0px", "0px", "0px", "0px", "20px"]}>
                  <FormLabel
                    color={"#707070"}
                    fontSize="13px"
                    fontWeight={"500"}
                  >
                    FULL NAME
                  </FormLabel>
                  <Input
                    borderRadius={"8px"}
                    border="1px solid #A0A0A0"
                    type="text"
                    {...register("fullName", {
                      required: "fullName must be required",
                    })}
                    required
                  />
                </FormControl>

                <FormControl mt={"20px"}>
                  <FormLabel
                    color={"#707070"}
                    fontSize="13px"
                    fontWeight={"500"}
                  >
                    EMAIL
                  </FormLabel>
                  <Input
                    type="email"
                    borderRadius={"8px"}
                    border="1px solid #A0A0A0"
                    {...register("email", {
                      required: "email must be required",
                    })}
                  />
                </FormControl>
                <FormControl mt={"20px"}>
                  <FormLabel
                    color={"#707070"}
                    fontSize="13px"
                    fontWeight={"500"}
                  >
                    PIN CODE
                  </FormLabel>
                  <Input
                    borderRadius={"8px"}
                    border="1px solid #A0A0A0"
                    {...register("pincode")}
                  />
                </FormControl>
              </GridItem>

              <GridItem colSpan={1}>
                <FormControl mt={["2px", "20px"]}>
                  <FormLabel
                    color={"#707070"}
                    fontSize="13px"
                    fontWeight={"500"}
                  >
                    STATE
                  </FormLabel>
                  <Select
                    placeholder="Select State"
                    borderRadius={"8px"}
                    border="1px solid #A0A0A0"
                    defaultValue={userData?.data?.state}
                    {...register("state", {
                      required: "state must be required",
                    })}
                  >
                    {states.map((el) => (
                      <option key={el} value={el}>
                        {el}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl mt={"20px"}>
                  <FormLabel
                    color={"#707070"}
                    fontSize="13px"
                    fontWeight={"500"}
                  >
                    MOBILE NUMBER
                  </FormLabel>
                  <Input
                    readOnly
                    type="tel"
                    borderRadius={"8px"}
                    border="1px solid #A0A0A0"
                    name="mobileNumber"
                    value={userData?.data?.mobileNo}
                    required
                  />
                </FormControl>
              </GridItem>
            </Grid>

            <Box mt={6} pb={[2, 6]}>
              <Button
                type="submit"
                colorScheme={"green.300"}
                color={"#FFFFFF"}
                w={"100%"}
                borderRadius="8px"
                bg={"#52771A"}
                mt={["10px", "10px", "10px", "10px", "20px"]}
              >
                SAVE CHANGES
              </Button>
            </Box>
          </form>
        </Box>
      )}
    </Box>
  );
}

export default EditProfileForm;
