// A mock function to mimic making an async request for data
import axios from "axios";
export function createCart( user_id, product) {
  return new Promise(async (resolve) => {
    if(!user_id){
      const productData = JSON.parse(localStorage.getItem("productData")) || {totalPrice : 0 ,data  :  { products: [] }};

      productData.data.products.push(product);
      let totalPrice = 0;
      productData.data.products.forEach((product) => {
        totalPrice += product.price * product.quantity;})
        productData.totalPrice = totalPrice;

      const arrayString = JSON.stringify(productData);
      localStorage.setItem("productData", arrayString);
      const data = JSON.parse(localStorage.getItem("productData"));
      resolve({ data })
    }else{
       const response = await axios.post(process.env.REACT_APP_BASE_API+"/cart/addToCart", {
   user_id,
   product
 });
   const data = response.data;
    resolve({ data });
    }

  });
}


export function fetchUserCart(user_id) {
  return new Promise(async (resolve) => {
    if(!user_id){
      const data = JSON.parse(localStorage.getItem("productData")) ;
      resolve({ data })
    }else{ 
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API}/cart/getUserCart/${user_id}`
    );
    const data = response.data;
    resolve({ data });
    }
  });
}

export function deleteProductFromCart(user_id, product_id) {
  return new Promise(async (resolve) => {
    if (!user_id) {
      const productData = JSON.parse(localStorage.getItem("productData")) || {totalPrice : 0 , data: { products: [] } };
      for (let i in productData?.data?.products) {
        if (productData?.data?.products[i].product_id === product_id.toString()) {
          productData?.data?.products.splice(i, 1);
          break;
        }
      }
     let totalPrice  = 0;
      productData.data.products.forEach((product) => {
        totalPrice += product.price * product.quantity;})
        productData.totalPrice = totalPrice;
      localStorage.setItem("productData", JSON.stringify(productData));
      const data = productData || null;
      resolve({ data });
    }else{
      const response =  await axios.post(process.env.REACT_APP_BASE_API+"/Cart/removeProductFromCart", {
        product_id: product_id,
        user_id: user_id,
      });
       const data = response.data;
      resolve({ data });
    }
  
  });
}
export function addProductQuantityFromCart(user_id, product_id,quantity) {
  return new Promise(async (resolve) => {
    if(!user_id){
      const productData = JSON.parse(localStorage.getItem("productData")) || { totalPrice: 0  ,data: {products: [] } };
      if(quantity === 0){
        const productIndex = productData?.data?.products.findIndex(
          (product) => product.product_id.toString() === product_id
        );   
        if (productIndex !== -1) {
          productData.data.products.splice(productIndex, 1);
        }
      }else{
        const productIndex = productData?.data?.products.findIndex(
          (product) => product.product_id.toString() === product_id
        );  
        if (productIndex !== -1) {
          productData.data.products[productIndex].quantity = quantity;
        }
      }
    
     let totalPrice = 0;
     productData.data.products.forEach((product) => {
      totalPrice += product.price * product.quantity;})
      productData.totalPrice = totalPrice;

      localStorage.setItem("productData", JSON.stringify(productData));
      const data = productData;
      resolve({ data });

    }else{
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/Cart/addProductQuantityFromCart",
      {
        product_id,
        user_id,
        quantity
      }
    );
    const data = response.data;
    resolve({ data });
    }
  });
}

