import React from "react";
import { Box, Button, Flex } from "@chakra-ui/react";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";

const Pagination = ({ currentPage, totalPages, onPageChange, itemPerPage }) => {
  const totalPagesToShow = Math.ceil(totalPages / itemPerPage);
  const currentSeries = Math.ceil(currentPage / itemPerPage);

  const getPageNumbers = () => {
    const startPage = (currentSeries - 1) * itemPerPage + 1;
    const endPage = Math.min(currentSeries * itemPerPage, totalPages);

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  const pageNumbers = getPageNumbers();

  return (
    <Flex justify="center" mt={4}>
        <Button 
          disabled={currentSeries === 1 && currentPage === 1}
          onClick={() => {
            if (currentPage === 1) {
              onPageChange((currentSeries - 2) * itemPerPage + itemPerPage);
            } else {
              onPageChange(currentPage - 1);
            }
          }}
          // colorScheme="teal"
        >
          <GrFormPrevious />
        </Button>
  
      <Box ml={4} display={"flex"} gap={"10px"}>
        {pageNumbers.map((pageNumber) => (
        
          <Button
            key={pageNumber}
            onClick={() => onPageChange(pageNumber)}
            backgroundColor={pageNumber === currentPage ? "#52771A" : "#D9D9D9"}
            border="1px solid #D9D9D9"
            color={"#FFFFFF"}
        
            variant="none"
          >
            {pageNumber}
          </Button>
        ))}
      </Box>
      <Box ml={4}>
        <Button
          disabled={
            currentSeries === totalPagesToShow && currentPage === totalPages
          }
          onClick={() => {
            if (currentPage === totalPages) {
              onPageChange(currentSeries * itemPerPage + 1);
            } else {
              onPageChange(currentPage + 1);
            }
          }}
          // colorScheme="teal"
        >
          <GrFormNext />
        </Button>
      </Box>
    </Flex>
  );
};
export default Pagination;
