import {
  Button,
  Center,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useRef } from "react";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { deleteProductFromCartAsync } from "../../features/Cart/CartSlice";

export const RemoveCartPopup = ({ product_id, user_id ,productName}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = useRef(null);
  const dispatch = useDispatch();
  const toast = useToast()

  const handleDelete = () => {
    dispatch(
      deleteProductFromCartAsync({
        user_id,
        product_id,
      })
    ).then((res)=>toast({ title: `Successfully removed ${productName} from your cart`,
    status: 'success',
    duration: 3000,
    isClosable: true}))
    onClose();
  };

  return (
    <>
      <IconButton
        icon={<RiDeleteBin6Fill />}
        fontSize={["22px","24px"]}
        color={"#8E8E8E"}
        variant="none"
        ml={"80%"}
        onClick={onOpen}
      />

      <Modal
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        motionPreset="slideInBottom" // Add a slide-in animation
      >
        <ModalOverlay opacity={0.7} /> {/* Partially transparent overlay */}
        <ModalContent p={[1,3]}>
          <ModalCloseButton />
          <ModalHeader>
            <Center>
              {" "}
              {/* Center the contents */}
              <Image
                src="https://www.netmeds.com/msassets/images/remove-product.svg"
                alt="Remove Product"
                boxSize={["100px","150px"]} // Adjust image size to make it larger
              />
            </Center>
            <Text textAlign="center" mt={3} fontSize={["20px","30px"]}>
              {" "}
              Remove Product
            </Text>
          </ModalHeader>
          <ModalBody>
            <Center>
              <Text textAlign="center" fontSize={["md","lg"]}>
                Are you sure you want to remove this product from your cart?
              </Text>
            </Center>
          </ModalBody>

          <ModalFooter>
            <Stack direction="row" spacing={2} width="100%" mt={3}>
              <Button flex="1" colorScheme="blue" onClick={onClose}>
                CANCEL
              </Button>
              <Button
                flex="1"
                variant="ghost"
                colorScheme="red"
                onClick={() => handleDelete()}
              >
                REMOVE
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
