import { ArrowBackIcon, SearchIcon } from "@chakra-ui/icons";
import {
    Box,
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ExploreProductCard from "../component/ExploreProductCard";
import ProductPageSkeleton from "../component/Skeletons/ProductPageSkeleton";

const Products = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const isMobile = useBreakpointValue({ base: true, sm: false });
  const navigate = useNavigate();
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  return (
    <Box bg={"linear-gradient(180deg, #f3ffe1 0%, #fff 100%)"}>
      <HStack  spacing={["2px","30px"]} ml={[2,10]} pt={5}>
        <Button 
          leftIcon={<ArrowBackIcon w={8} h={8} color={"#52771A"}/>}
          color="#52771A"
          variant="none"
          onClick={() => navigate(-1)}
        >
        {isMobile ? "":"Go back"} 
        </Button>
        <InputGroup  >
          <InputLeftElement children={<SearchIcon />} />
          <Input
            placeholder="Search your Product..."
            value={searchTerm}
            onChange={handleSearch}
            bgColor="gray.100"
            borderRadius={"50px"}
            fontSize={"lg"}
            focusBorderColor="#52771A"
            _placeholder={{ color: "gray.400" }}
            w={["90%","80%"]}
          />
        </InputGroup>
      </HStack >
      <Box gap={["10px","20px"]} mt={"30px"}
          style={{
            display: "grid",
            justifyContent: "center",
          
          }}
          gridTemplateColumns={[
            "repeat(2,1fr)",
            "repeat(2,1fr)",
            "repeat(2,1fr)",
            "repeat(3,1fr)",
            "repeat(4,1fr)",
          ]}
        >
             <ExploreProductCard/>
             <ExploreProductCard/>
             <ExploreProductCard/>
             <ExploreProductCard/>
             <ExploreProductCard/>
             <ExploreProductCard/>
             <ExploreProductCard/>
             <ExploreProductCard/>
             <ExploreProductCard/>
             <ExploreProductCard/>
             <ExploreProductCard/>
             <ExploreProductCard/>
             <ProductPageSkeleton/>
        </Box>
    </Box>
  );
};

export default Products;
