import { Box, Button, Collapse, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import "./ServiceBox.css";
import ContactUsModal from "../../component/Modal/ContactUsModal";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import YoutubeEmbed from "../../utils/YoutubeEmbed";

const ServiceBox = ({ id, name, content, vedioLink }) => {
  const [show, setShow] = useState(false);
  const characterLimit = 500; // Adjust this as needed
  const handleToggle = () => setShow(!show);
  const isContentLong = content.length > characterLimit;
  const videoId = vedioLink.split("v=")[1];
  // const opts = {
  //   height: "390",
  //   width: "640",
  //   playerVars: {
  //     // https://developers.google.com/youtube/player_parameters
  //     autoplay: 0,
  //   },
  // };
  return (
    <Box
      display={["flex","flex","flex","flex","grid"]}
      gridTemplateColumns={["1fr", "1fr", "1fr", "1fr", "2fr 1fr"]}
      w={["auto", "auto", "auto", "auto", "1300px"]} justifyContent={"center"} gap={"20px"} h={"auto"} margin={"auto"} mt={["10px","40px"]} p={["10px","30px"]} borderRadius={"24px"} bg={"#fff"} mb={["10px","80px"]} boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
      flexDirection={{base:"column-reverse",sm:"column-reverse",md:"column-reverse",lg:"column-reverse"}}
    >
      <Box mt>
        <Text fontSize={["20px","36px"]} id="service_heading">{name}</Text>
        <Box w={["auto", "auto", "auto", "auto", "700px"]}>
          <Collapse startingHeight={190} in={show}>
            <Text id="service_details" mt={[2,4]} textAlign={"justify"} fontSize={["10px","16px"]}>
              {content}
            </Text>
          </Collapse>
          {isContentLong && (
            <Button
              size="sm"
              onClick={handleToggle}
              variant="ghost"
              mt={"10px"}
              color="#52771A"
              fontSize={"16px"}
              fontWeight="600"
              fontFamily={"Inter"}
              rightIcon={
                show ? (
                  <ChevronUpIcon color={"#52771A"} w="15" h={"9"} />
                ) : (
                  <ChevronDownIcon color={"#52771A"} w="15" h={"9"} />
                )
              }
            >
              {show ? "Read Less" : "Read More"}
            </Button>
          )}
        </Box>
        <ContactUsModal contact={"Service Contact"} heading={name} />
      </Box>
      <Box mt={["5px","20px"]}>
        <YoutubeEmbed embedId={videoId}/>
      </Box>
    </Box>
  );
};

export default ServiceBox;
