import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { addDeliveryAddress, checkPhoneNo, sendOtp,updateUser,verifyOtp,deleteDeliveryAddress,getDeliveryAddress, fetchSelectedAddress, fetchUser, fetchUserProfile, forgetPassword, loginUser, registerUser, updateDeliveryAddress, updateSelectedAddress } from "./UserAPI";


const initialState = {
  value: 0,
  status: "idle",
  userdata: null,
  checkNo: null,
  loginUserData: null,
  userAddress:{data:[]},
  selectedAddressData: null,
};

export const fetchUserProfileAsync = createAsyncThunk(
  "user/fetchUserProfile",
  async () => {
    const response = await fetchUserProfile();
    return response.data;
  }
);

export const checkMobilNoAsync = createAsyncThunk(
  "user/checkPhoneNo",
  async (phoneNo) => {
    const response = await checkPhoneNo(phoneNo);
    return response.data;
  }
);
export const registerUserAsync = createAsyncThunk(
  "user/registerUser",
  async (data) => {
    const response = await registerUser(data);
    return response.data;
  }
);
export const otpVerifyUserAsync = createAsyncThunk(
  "user/verifyOtp",
  async ({mobile,otp}) => {
    const response = await verifyOtp({mobile,otp});
    return response.data;
  }
);
export const sendOtpUserAsync = createAsyncThunk(
  "user/sendOtp",
  async (mobile) => {
    const response = await sendOtp(mobile);
    return response.data;
  }
);
export const fetchUserAsync = createAsyncThunk(
  "user/fetchUser",
  async ({ id }) => {
    const response = await fetchUser(id);
    return response.data;
  }
);
export const fetchSelectedAddressAsync = createAsyncThunk(
  "user/fetchSelectedAddress",
  async ({ id }) => {
    const response = await fetchSelectedAddress(id);

    return response.data;
  }
);
export const updateDeliveryAddressAsync = createAsyncThunk(
  "user/updateDeliveryAddress",
  async ({ user_id, address_id, data }) => {
    const response = await updateDeliveryAddress(user_id, address_id, data);
    return response.data;
  }
);
export const updateSelectedAddressAsync = createAsyncThunk(
  "user/updateSelectedAddress",
  async ({ user_id, address_id}) => {
    const response = await updateSelectedAddress(user_id, address_id);
    return response.data;
  }
);
export const updateUserAsync = createAsyncThunk(
  "user/updateUser",
  async ({UserData,userId}) => {
    const response = await updateUser({UserData,userId});
    return response.data;
  }
);
export const deleteDeliveryAddressAsync = createAsyncThunk(
  "user/deleteDeliveryAddress",
  async ({ user_id, address_id }) => {
    const response = await deleteDeliveryAddress(user_id, address_id);
    return response.data;
  }
);
export const addDeliveryAddressrAsync = createAsyncThunk(
  "user/addDeliveryAddress",
  async ({ id, data }) => {
    const response = await addDeliveryAddress(id, data);
    return response.data;
  }
);
export const getDeliveryAddressrAsync = createAsyncThunk(
  "user/getDeliveryAddress",
  async ({ id, data }) => {
    const response = await getDeliveryAddress(id);
    return response.data;
  }
);
export const forgetPasswordAsync = createAsyncThunk(
  "user/forgetPassword",
  async (data) => {
    const response = await forgetPassword(data);
    return response.data;
  }
);
export const loginUserAsync = createAsyncThunk(
  "user/loginUser",
  async (data) => {
    const response = await loginUser(data);
    return response.data;
  }
);

export const UserSlice = createSlice({
  name: "user",
  initialState,

  reducers: {
    increment: (state) => {
      state.value += 1;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfileAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserProfileAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.loginUserData = action.payload;
      })
      .addCase(updateUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.userdata = action.payload;
      })
      .addCase(fetchSelectedAddressAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSelectedAddressAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.selectedAddressData = action.payload;
      })
      .addCase(getDeliveryAddressrAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDeliveryAddressrAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.userAddress = action.payload;
      })
      .addCase(addDeliveryAddressrAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addDeliveryAddressrAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.userAddress.data.push(action.payload.data);
      })
      .addCase(updateDeliveryAddressAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateDeliveryAddressAsync.fulfilled, (state, action) => {
        state.status = "idle";

        let newArr = state.userAddress.data?.map(function (
          item
        ) {
          if (item._id === action.payload.data._id) {
            return { ...item, ...action.payload.data};
          } else {
            return item;
          }
        });
        state.userAddress ={...state.userAddress , data:newArr} ;
      })
      .addCase(updateSelectedAddressAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateSelectedAddressAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.selectedAddressData = action.payload;
      })
      .addCase(deleteDeliveryAddressAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteDeliveryAddressAsync.fulfilled, (state, action) => {
        state.status = "idle";
        let newArr = state.userAddress?.data?.filter(function (item) {
          return item._id !== action.payload.data._id;
        });
         state.userAddress ={...state.userAddress , data:newArr}
      })
      .addCase(checkMobilNoAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(checkMobilNoAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.checkNo = action.payload;
      })

      .addCase(fetchUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.userdata = action.payload;
      });
  },
});

export const userSelector = (state) => state.user.userdata;
export const loginUserSelector = (state) => state.user.loginUserData;
export const userAddressSelector = (state) => state.user.userAddress;
export const selectedAddresSelector = (state) => state.user.selectedAddressData;

export default UserSlice.reducer;
