import { Box, Container, Heading, Image, ListItem, Stack, UnorderedList } from '@chakra-ui/react'
import React from 'react'

const CancellationPolicy = () => {
    const CancelPolicyImage = "https://static.vecteezy.com/system/resources/previews/003/058/655/original/cancel-order-and-package-vector.jpg"
  return (
    <Box bg={"linear-gradient(180deg, #f3ffe1 0%, #fff 100%)"}>
    <Container maxW="container.lg" p={5}>
      <Box textAlign="center" mb={5}>
        <Image src={CancelPolicyImage} alt="Privacy Policy"  borderRadius="md"
          objectFit="contain"
          width="100%"
          height="250px" />
      </Box>
      <Heading as="h1" mb={5}>
      Cancellation Policy
      </Heading>
      <Stack spacing={5}>
     <UnorderedList>
        <ListItem>If a customer changes their mind and wants to cancel the order, then they have to contact our Customer Care executive. The cancellation will not be valid until it is confirmed by our respective department.
</ListItem>
<ListItem>Cancellation requests will be considered only if the product is not dispatched. Once the order is shipped, customer cannot cancel the order.</ListItem>
<ListItem>If the customer has placed an order through Online Payment and cancels the order, then we will process for a refund within 7 working days.
</ListItem>
<ListItem>NOTE: If you have done an online payment and you are cancelling the order,  the payment gateway charge of 3% will be deducted from your refund amount (Before Shipped). If you have ordered and the product is in transit (shipped) we will charge 3% and courier charge (Forward  + Return ) from you.
</ListItem>
     </UnorderedList>
       
      </Stack>
    </Container>
    </Box>
  )
}

export default CancellationPolicy
