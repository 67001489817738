import React from 'react'
import {Box, Image} from "@chakra-ui/react";
import { NavLink, useLocation } from 'react-router-dom';
import homeIcon from "../../images/home.svg"
import homeFillIcon from "../../images/homefill.svg"
import aboutIcon from "../../images/About Us.svg"
import aboutFillIcon from "../../images/About Us Filled.svg"
import govtIcon from "../../images/Govt Schemes.svg"
import govtFillIcon from "../../images/Govtfilled.svg"
import productIcon from "../../images/cubes.svg"
import productFillIcon from "../../images/cubesfill.svg"
import serviceIcon from "../../images/Service.svg"
import serviceFillIcon from "../../images/Service filled.svg"

const BottomNav = () => {
  const location = useLocation();
  return (
    <Box position={"sticky"} bottom={0} left={0} w={"100%"} bg={"#f3ffe1"} display={"flex"} justifyContent={"space-around"} alignItems={"center"} p={"10px"}>
    <NavLink to="/">
    {location.pathname === "/" ? <Image src={homeFillIcon} w={"24px"} h={"24px"}/>:<Image src={homeIcon} w={"24px"} h={"24px"}/>}  
    </NavLink>
    <NavLink to="/products">
   {location.pathname === "/products" ?<Image src={productFillIcon} w={"24px"} h={"24px"}/>:<Image src={productIcon} w={"24px"} h={"24px"}/>}
    </NavLink>
    <NavLink to="/about">{location.pathname === "/about" ?<Image src={aboutFillIcon} w={"24px"} h={"24px"}/>:<Image src={aboutIcon} w={"24px"} h={"24px"}/>}</NavLink>
    <NavLink to="/service">{location.pathname === "/service" ?<Image src={serviceFillIcon} w={"24px"} h={"24px"}/>:<Image src={serviceIcon} w={"24px"} h={"24px"}/>}</NavLink>
    <NavLink to="/govtscheme">{location.pathname === "/govtscheme" ?<Image src={govtFillIcon} w={"24px"} h={"24px"}/>:<Image src={govtIcon} w={"24px"} h={"24px"}/>}</NavLink>
  </Box>
  )
}

export default BottomNav
