import { Box,Skeleton } from "@chakra-ui/react";
import React from "react";

const ExploreProductCardSkeleton = () => {
  return (
    <>
      {new Array(12).fill("loading")?.map((el,i) => (
      <Box key={i} border={"1px solid #FFF"}
      w={["180px","300px"]} 
      h="auto"
      borderRadius="14px"
      boxShadow='lg' bg='white'
      margin={"auto"}
    >
       
          <Skeleton  w={["100px","191px"]} h={["100px","191px"]} mx="auto" mt="30px" bg={"#f4f4f4"}/>
          <Skeleton width={"80%"} h={5} margin="auto" bg={"#f4f4f4"} mt="20px"></Skeleton>
          <Skeleton width={"40px"} h={5}  ml={"10px"} bg={"#f4f4f4"} mt="20px"></Skeleton>
       
        <Skeleton
          width={"80%"}
          borderRadius="7px"
          mt="20px"
          mx="auto"
          mb={"20px"}
          h={10}
        >
        </Skeleton>
    </Box>
      ))}
    </>
  
  );
};

export default ExploreProductCardSkeleton;
