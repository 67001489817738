import { Box, Button, Divider, Flex, Spinner, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ExploreProductCard from "../component/ExploreProductCard";
import Pagination from "../component/Pagination";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategoryByIdAsync,
  selectCategory,
  selectCategoryById,
} from "../features/Category/CategorySlice";
import {
  fetchSubCategoryByCategoryIdAsync,
  selectSubcategoryByCategoryId,
} from "../features/Subcategory/SubcategorySlice";
import CategoryCarousel from "../component/Carousels/CategoryCarousel";

const CategoryPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const categoryById = useSelector(selectCategoryById);
    const categeory= useSelector(selectCategory);
    const[allproduct,setAllproduct] = useState(null);
const [loading ,setLoading] =useState(true);
  const SubCategorySelector = useSelector(selectSubcategoryByCategoryId);

  useEffect(() => {
    dispatch(fetchCategoryByIdAsync(id)).then((res) => {
      if (res.payload.status === 1) {
        setLoading(false);
      }
    });
    dispatch(fetchSubCategoryByCategoryIdAsync(id));
  }, [id]);

  useEffect(()=>{
  const result = categeory.data.find((el)=> el._id === id); 
  setAllproduct(result);
  },[id]);
 
  return (
    <>
     {loading ? (
        <Flex justifyContent={"center"} m={"auto"} h={"400px"}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="#52771A"
            size="xl"
          />
        </Flex>
      ) : (
    <Box>
      
      <Box
        width={"100%"}
        h="auto"
        backgroundColor="#FFF7E9"
        padding="24px"
      >
        <Flex alignItems="center">
          <Divider flex="1" borderColor="#FFFFFF" borderWidth="8px" />
          <Text
            mx={4}
            color="#404040"
            textShadow="0px 4px 4px #F29800"
            fontFamily="Kaushan Script"
            fontSize={["18px","64px"]}
            fontWeight="400"
          >
            {categoryById?.data[0].categoryName}
          </Text>
          <Divider flex="1" borderColor="#FFFFFF" borderWidth="8px" />
        </Flex>

        <VStack spacing={4} mt={6} textAlign={"justify"}>
          <Text fontSize={["10px","18px"]} color="#404040">
            {categoryById?.data[0].categoryDescription}
          </Text>

          <Button
            color="#FFF"
            fontFamily="Inter"
            fontSize={["10px","16px"]}
            fontWeight="500"
            lineHeight="normal"
            width={["100px","232px"]}
            height={["30px","51px"]}
            borderRadius="7px"
            backgroundColor="#F6B445"
            _hover={{ backgroundColor: "#F29800" }}
          >
            READ MORE
          </Button>
        </VStack>
      </Box>

      <Box width={"100%"} height={["350px","401px"]} pb="5%" backgroundColor={"#F3FFE1"}>
        <Box paddingTop={["10px","30px"]}>
          <Text fontSize={["20px","32px"]}
            style={{
              color: "#404040",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            Shop by Category
          </Text>
        </Box>
        <Box
          style={{
            marginTop: "30px",
            width: "100%",
            margin: "auto",
          }}
        >
          {SubCategorySelector?.status === 1 && (
            <CategoryCarousel
              categorydata={SubCategorySelector?.data}
              allproduct={allproduct}
            />
          )}
        </Box>

        
        
      </Box>

      <Box>
        <Box mt={"20px"} mb="20px" ml="40px">
          <Text fontSize={["20px","32px"]}
            style={{
              color: "#404040",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "700",
            }}
          >
            ALL PRODUCTS
          </Text>
        </Box>

        <Box
          style={{
            display: "grid",
            justifyContent: "center",
            gap: "20px",
          }}
          gridTemplateColumns={[
            "repeat(2,1fr)",
            "repeat(2,1fr)",
            "repeat(2,1fr)",
            "repeat(3,1fr)",
            "repeat(4,1fr)",
          ]}
        >
          <ExploreProductCard />
          <ExploreProductCard />
          <ExploreProductCard />
          <ExploreProductCard />
          <ExploreProductCard />
          <ExploreProductCard />
          <ExploreProductCard />
        </Box>
      </Box>

      <Pagination totalPages={10} currentPage={1} />
    </Box>) }</>
  );
};

export default CategoryPage;
