import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const CategoryNameCard = ({ category_name, image, id }) => {
  return (
    <Link to={`/categorydetailpage/${id}`}>
      <Box
        width={["100%", "237.699px"]}
        h={["150px", "250px"]}
        style={{
          borderRadius: "28px",
          background: "#FFF",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
          margin: "auto",
        }}
      >
        <Image
          src={image}
          width={["100%", "237.699px"]}
          h={["100px", "161px"]}
          style={{
            borderRadius: "28px 0px 28px 28px",
            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.10))",
          }}
        />
        <Box width={"80%"} margin="auto">
          <Text
            fontSize={["10px", "20px"]}
            mt={["10%", "10%"]}
            style={{
              color: "#000",
              textAlign: "center",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              textTransform: "uppercase",
            }}
          >
            {category_name}
          </Text>
        </Box>
      </Box>
    </Link>
  );
};

export default CategoryNameCard;
