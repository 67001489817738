import React from "react";
import { Route, Routes } from "react-router-dom";
import Contact from "./Contact";
import Service from "./ServicePage/Service";
import GovtSchemePage from "./GovtScheme/GovtSchemePage";
import About from "./About";
import ProductDetailPage from "./ProductDetailPage";
import CategoryPage from "./CategoryPage";
import AccountPage from "../component/MyAccount/AccountPage";
import EditProfileForm from "./../component/Form/EditProfileform";
import PrivateRoutes from "./PrivateRoute/PrivateRoute";
import CartPage from "./CartPage";
import DelieveryAddress from "../component/MyAccount/DelieveryAddress";
import MyOrders from "../component/MyAccount/MyOrders";
import Home from "./ProductPage/Home";
import Products from "./Products";
import AddressPage from "../component/AddressPage";
import PrivacyPolicy from "./PrivacyPolicy";
import Faq from "./Faq";
import TermsOfUse from "./TermsOfUse";
import ReturnPolicy from "./ReturnPolicy";
import ScrollToTop from "../utils/ScrollToTop";
import ShippingPolicy from "./ShippingPolicy";
import CancellationPolicy from "./CancellationPolicy";
import NotFound from "./NotFound";
import NoResultFound from "./NoResultFound";
import AdminLogin from "./AdminLogin";

const RouterPage = () => {
  return (
      <ScrollToTop>
    <Routes>
      <Route path="/" element={<AdminLogin/>}></Route>
      <Route path="/home" element={<Home/>}></Route>
      <Route path="/products" element={<Products/>}></Route>
      <Route path="/contact" element={<Contact />}></Route>
      <Route path="/about" element={<About />}></Route>
      <Route path="/service" element={<Service />}></Route>
      <Route path="/govtscheme" element={<GovtSchemePage />}></Route>
      <Route path="/addresspage" element={<PrivateRoutes><AddressPage/></PrivateRoutes>}></Route>
      <Route path="/privacypolicy" element={<PrivacyPolicy/>}></Route>
      <Route path="/faq" element={<Faq/>}></Route>
      <Route path="/termsofuse" element={<TermsOfUse/>}></Route>
      <Route path="/returnpolicy" element={<ReturnPolicy/>}></Route>
      <Route path="/shippingpolicy" element={<ShippingPolicy/>}></Route>
      <Route path="/cancelpolicy" element={<CancellationPolicy/>}></Route>
      
    
      <Route
        path="/productdetailpage/:id"
        element={<ProductDetailPage />}
      ></Route>
      <Route
        path="/categorydetailpage/:id"
        element={<CategoryPage />}
        ></Route>
    

      <Route
        path="/myaccount"
        element={
          <PrivateRoutes>
            <AccountPage />
          </PrivateRoutes>
        }
      />
      <Route
        path="/cart"
        element={
            <CartPage/>
          
        }
      />
      <Route path="/editProfileForm" element={<EditProfileForm />}></Route>
      <Route path="/delieveryaddress" element={<DelieveryAddress />}></Route>
      <Route path="/myorders" element={<MyOrders />}></Route>
      <Route path="/noresult" element={<NoResultFound />}></Route>
      <Route path="*" element={<NotFound/>}></Route>
    </Routes>
      </ScrollToTop>
  );
};

export default RouterPage;
