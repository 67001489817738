import { Box, Skeleton } from "@chakra-ui/react";
import React from "react";

const CategoryNameCardSkeleton = () => {
  return (
    <>
      {new Array(5).fill("loading").map((el, i) => (
        <Box key={i}
          width={["100%", "237.699px"]}
          h={["150px", "250px"]}
          style={{
            borderRadius: "28px",
            background: "#FFF",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
            margin: "auto",
          }}
        >
          <Skeleton
            width={["100%", "237.699px"]}
            h={["100px", "161px"]}
            style={{
              borderRadius: "28px 0px 28px 28px",
              filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.10))",
            }}
            bg={"#f4f4f4"}
          ></Skeleton>
          <Skeleton width={"80%"} margin="auto" bg={"#f4f4f4"} mt={"10%"} h={3}>
            {" "}
          </Skeleton>
          <Skeleton
            width={"30%"}
            margin="auto"
            bg={"#f4f4f4"}
            h={3}
            mt={2}
          ></Skeleton>
        </Box>
      ))}
    </>
  );
};

export default CategoryNameCardSkeleton;
