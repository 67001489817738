import React from "react";
import ExploreProductCardSkeleton from "./ExploreProductCardSkeleton";

const ProductPageSkeleton = () => {
  return (
    <>
      {new Array(10).fill("loading").map((el,i) => (
        <ExploreProductCardSkeleton key={i}/>
      ))}
    </>
  );
};

export default ProductPageSkeleton;
