import { ChevronRightIcon } from "@chakra-ui/icons";
import {Box,IconButton,Text,useBreakpointValue} from "@chakra-ui/react";
import React, { useState } from "react";
import { IoMdPin } from "react-icons/io";
import { TbTruckDelivery } from "react-icons/tb";
import { IoLogOut } from "react-icons/io5";
import { BsPersonCircle } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserProfileAsync,
  loginUserSelector,
} from "../../features/User/UserSlice";
import { Link } from "react-router-dom";
const MyAccountOptions = ({ handleOption }) => {
  const [selectedOption, setSelectedOption] = useState(0);
  const loginUser = useSelector(loginUserSelector);
  const dispatch = useDispatch();
  const isMobile = useBreakpointValue({ base: true,lg:false });
  const options = [
    {
      label: "Delivery Addresses",
      iconName: <IoMdPin w={"17px"} h="17px" />,
      redirectionName: "/delieveryaddress",
    },
    {
      label: "My Orders",
      iconName: <TbTruckDelivery w={"17px"} h="17px" />,
      redirectionName: "/myorders",
    },
    {
      label: "Logout",
      iconName: <IoLogOut w={"17px"} h="17px" />,
    },
  ];
  if (!isMobile) {
    options.unshift({
      label: "Account Information",
      iconName: <BsPersonCircle w={"17px"} h="17px" />,
      redirectionName: "/personalinfo",
    });
  }
  const handleClick = (index) => {
    handleOption(index + 1);
    setSelectedOption(index);
    if (index === 3) {
      if (loginUser?.status === 1) {
        localStorage.removeItem("token");
        dispatch(fetchUserProfileAsync());
      }
    }
  };

  return (
    <Box
      w={["100%","100%","100%","100%","400px"]}
      h="auto"
      borderRadius={"14px"}
      backgroundColor="#FFFFFF"
      boxShadow="lg"
    >
      {options.map((option, index) => (
       isMobile ? <Link to={option.redirectionName}  key={index}>
          <Box
            display="flex"
            alignItems="center"
            p={3}
            cursor="pointer"
            color={"#707070"}
            backgroundColor={selectedOption === index ? "#E2E8F0" : "white"}
            fontWeight={selectedOption === index ? 700 : 400}
            _hover={{ backgroundColor: "#E2E8F0" }}
            onClick={() => handleClick(index+1)}
          >
            <IconButton
              fontSize="27px"
              borderRadius={"50%"}
              backgroundColor={selectedOption === index ? "#F3FFE1" : null}
              color={selectedOption === index ? "#52771A" : "#707070"}
            >
              {option.iconName}
            </IconButton>
            <Text ml={3}>{option.label}</Text>
            <ChevronRightIcon
              ml="auto"
              color={selectedOption === index ? "#52771A" : null}
            />
          </Box>
        </Link> :(
           <Box
           key={index}
           display="flex"
           alignItems="center"
           p={3}
           cursor="pointer"
           color={"#707070"}
           backgroundColor={selectedOption === index ? "#E2E8F0" : "white"}
           fontWeight={selectedOption === index ? 700 : 400}
           _hover={{ backgroundColor: "#E2E8F0" }}
           onClick={() => handleClick(index)}
         >
           <IconButton
             fontSize="27px"
             borderRadius={"50%"}
             backgroundColor={selectedOption === index ? "#F3FFE1" : null}
             color={selectedOption === index ? "#52771A" : "#707070"}
           >
             {option.iconName}
           </IconButton>
           <Text ml={3}>{option.label}</Text>
           <ChevronRightIcon
             ml="auto"
             color={selectedOption === index ? "#52771A" : null}
           />
         </Box>
        )
      ))}
    </Box>
  );
};

export default MyAccountOptions;
