import { Box } from "@chakra-ui/react";
import { FaHome, FaTag } from "react-icons/fa";
import { GoVerified } from "react-icons/go";
import { MdContactPage, MdOutlineMiscellaneousServices, MdOutlineRoundaboutRight } from "react-icons/md";
import { FaCircleExclamation } from "react-icons/fa6";

  const Icon = ({ type, color = "#E57A27", size = 20 }) => {
    const getIcon = () => {
      const iconTypeMap = {
        PRODUCT:<FaTag color={color} size={size}/>,
       SERVICE:<MdOutlineMiscellaneousServices  color={color} size={size} />,
       ABOUT:<MdOutlineRoundaboutRight color={color} size={size}/>,
       SCHEME:<GoVerified color={color} size={size}/>,
       CONTACT:<MdContactPage color={color} size={size}/>,
       HOME:<FaHome color={color} size={size}/>,
       EXCLAMATORYMARK:<FaCircleExclamation color={color} size={size}/>
      };
      return iconTypeMap?.[type];
    };
  
    return <Box>{getIcon()}</Box>;
  };
  
  export default Icon;
  