import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { loginUserSelector } from "../../features/User/UserSlice";
import { useEffect } from "react";
import { useToast } from '@chakra-ui/react'

const PrivateRoutes = ({ children }) => {
  const toast = useToast()
const loginUser = useSelector(loginUserSelector);
useEffect(()=>{
  if(loginUser?.status === 0){
    toast({
      position:"top",
      title: 'You Must Login First.',
      status: 'success',
      duration: 6000,
      isClosable: true,
    })
  }
 
},[])
  if (loginUser?.status === 0) {
    return <Navigate to={"/"} />;
  }
  return children;
};

export default PrivateRoutes;
