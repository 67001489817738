import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  useDisclosure,
  Box,
  Button,
  Input,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Heading,
  useToast,
} from "@chakra-ui/react";
import "../Form/Form.css";
import {
  nameIcon,
  emailIcon,
  phoneIcon,
  stateIcon,
  PincodeIcon,
  heartIcon,
} from "./../constant/Icons";
import { createContactUsAsync } from "../../features/ContactUs/ContactUsSlice";
import { useDispatch } from "react-redux";
function ContactUsModal({ contact, heading, state ,stateEditable=false}) {
  const { register, setValue, handleSubmit } = useForm();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const contact_form_style = {
    inputs: {
      width: "100%",
      height: "50px",
      borderRadius: "8px",
      border: "1px solid #A0A0A0",
    },
    inputsmall: {
      width: "139px",
      height: "50px",
      borderRadius: "8px",
      border: "1px solid #A0A0A0",
    },
    btn: {
      borderRadius: "8px",
      background: "#52771A",
      color: "#FFF",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "500",
      marginTop: "30px",

      marginBottom: "30px",
    },
    btn_for_govt_scheme: {
      borderRadius: "14px",
      background: "#52771A",
      color: "#FFF",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
    },
  };

  useEffect(() => {
    setValue("state", state);
  }, [state]);

  const dispatch = useDispatch();
  const onSubmit = (data) => {
    data.reletedTo = contact;
    data.name = heading;
    dispatch(createContactUsAsync(data))
      .then((res) => onClose())
      .then((res) => {
        toast({
          title: "Application submitted!",
          description:"Thanks for submitting your application. Our team will get back to you soon.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      {contact === "Service Contact" ? (
        <Button
          onClick={onOpen}
          borderRadius={"8px"}
          bg={"#52771A"}
          color={"#FFF"}
          fontSize={"16px"}
          fontWeight={"500"}
          mt={"30px"}
          fontFamily={"Inter"}
          mb={"30px"}
        >
          Contact us
        </Button>
      ) : null}

      {contact === "GovermentScheme Contact" ? (
        <Button
          onClick={onOpen}
          style={contact_form_style.btn_for_govt_scheme}
          mt={"15px"}
        >
          {" "}
          Contact us
        </Button>
      ) : null}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          style={{
            backdropFilter: "blur(2px)", // Apply the backdrop blur effect
          }}
        />
        <ModalContent
          width={["350px", "700px"]}
          height="auto"
          borderRadius="28px"
          background="#fff"
        >
          <ModalCloseButton
            backgroundColor="#8E8E8E"
            borderRadius="50%"
            color="white"
          />
          <Box
            // width="370px"
            // borderRadius="0px 0px 25px 25px"
            backgroundColor="#52771A"
            p={"10px"}
            borderRadius={"30px 30px 50px 50px"}
          >
            <Heading
              textAlign={"center"}
              color="#fff"
              fontSize={["20px", "30px"]}
            >
              {heading}
            </Heading>
          </Box>
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box display={"flex"} gap="20px" marginTop="20px">
                <Box marginTop={"-2px"}>{heartIcon()}</Box>
                <Text id="form_heading">Hello, Let’s get in touch</Text>
              </Box>

              <Box className="form-group">
                {nameIcon()}
                <Text className="form-label">Name</Text>
              </Box>
              <Input
                id="Username"
                {...register("Username", {
                  required: "Name must be required",
                })}
                style={contact_form_style.inputs}
              />

              <Box className="form-group">
                {emailIcon()}
                <Text className="form-label">Email ID</Text>
              </Box>
              <Input
                id="Useremail"
                {...register("Useremail")}
                style={contact_form_style.inputs}
              />

              <Box className="form-group">
                {phoneIcon()}
                <Text className="form-label">Phone no.</Text>
              </Box>
              <Input
                id="UserphoneNo"
                {...register("UserphoneNo", {
                  required: "phoneNo must be required",
                })}
                style={contact_form_style.inputs}
              />
              <Box display={"flex"} gap={["20px", "120px"]}>
                <Box>
                  <Box className="form-group">
                    {stateIcon()}
                    <Text className="form-label">State</Text>
                  </Box>
                  <Input isReadOnly={stateEditable}
                    id="state"
                    {...register("state", {
                      required: "state must be required",
                    })}
                    style={contact_form_style.inputs}
                  />
                </Box>
                <Box>
                  <Box className="form-group">
                    {PincodeIcon()}
                    <Text className="form-label">Pin Code</Text>
                  </Box>
                  <Input
                    id="pincode"
                    {...register("pincode", {
                      required: "pincode must be required",
                    })}
                    style={contact_form_style.inputsmall}
                  />
                </Box>
              </Box>

              <Button
                type="submit"
                style={contact_form_style.btn}
                ml={["155px", "255px"]}
              >
                Send Message
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ContactUsModal;
