import { Box, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing

const SocialLinksRedirections = ({ iconSrc, altText, socialUrl }) => {
  return (
    <Link to={socialUrl} target="_blank" rel="noopener noreferrer">
      <Box className="icon_or_text" justifyContent={["center","center","center","center","left"]}>
        <Image src={iconSrc} alt={altText} />
        <Text className="normal_text" fontSize={["10px", "16px"]}>
          {altText}
        </Text>
      </Box>
    </Link>
  );
};

export default SocialLinksRedirections;
