import { Box, Button, Image, Text, VStack, Center, Avatar } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import logo from "./../images/logo.jpg";
import { Link, useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 3000); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [navigate]);

  return (
    <Center bg="gray.50" p={4} >
      <VStack spacing={6} textAlign="center">
        <Avatar src={logo} bg={"green.100"} size={['lg', 'lg','lg','xl', '2xl']} alt="Logo" />
        <Text fontSize={['xl','xl','xl', '2xl', '3xl']} color={"red"} fontWeight="bold">Oops!</Text>
        <Text fontSize={['md', 'lg', 'xl']} color="gray.600">
          Something went wrong. Sorry for the inconvenience!
        </Text>
        <Text fontSize={['md', 'lg', 'xl']} color="gray.600">
         <Text as={"b"}>404!</Text>  We are transferring you to the home page. If the page does not automatically reload,
        </Text>
        <Text fontSize={['md', 'lg', 'xl']} color="gray.600">
          please click on the following button to go to the home page.
        </Text>
        <Link to="/">
          <Button bg="#52771A" color="#fff" size="lg">
            Home Page
          </Button>
        </Link>
      </VStack>
    </Center>
  );
};

export default NotFound;
