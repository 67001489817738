import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  HStack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { PiShoppingCartSimpleFill } from "react-icons/pi";
import { VscPreview } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { AddAddressForm } from "./Form/AddAddressForm";
import SelectAddress from "./MyAccount/SelecteAddress";
import { useSelector } from "react-redux";
import { userAddressSelector, userSelector } from "../features/User/UserSlice";
import Icon from "./constant/Icon";

const AddressPage = () => {
  const diliveryAddress = useSelector(userAddressSelector);
  if (diliveryAddress?.status === 1) {
    var address = diliveryAddress.data;
  }
  const userData = useSelector(userSelector);
  return (
    <>
      <Box 
        w={["90%","90%","90%","90%","1160px"]}
        h="70px"
        borderRadius={"14px"}
        background="#151B39"
        display={"flex"}
        justifyContent="space-between"
        alignItems={"center"}
        margin="auto"
      >
        <Text
          color={"#FFFFFF"}
          fontSize={{ base: "20px", lg: "32px" }}
          fontWeight={"700"}
          ml="2%"
        >
          Order Review
        </Text>

        <Box display={"flex"} mr="2%">
          <Box>
            <Button variant={"ghost"}>
              {" "}
              <PiShoppingCartSimpleFill color="#FFFFFF" />
            </Button>
            <Text color={"#FFFFFF"} fontSize="12px" fontWeight={"400"}>
              Your Cart
            </Text>
          </Box>
          <Divider mt={5} w={"50px"} h="1px" background={"#D9D9D9"} />
          <Box>
            <Button variant={"ghost"} ml={"8px"}>
              <VscPreview w={"24px"} h="24px" color="#FFFFFF" />
            </Button>
            <Text color={"#FFFFFF"} fontSize="12px" fontWeight={"400"}>
              Order Review
            </Text>
          </Box>
        </Box>
      </Box>
      <Flex flexDirection={["column","column","column","column","row"]}
        pt={"10px"}
        w={["90%","90%","90%","90%","1160px"]}
        justifyContent={"space-between"}
        m={"auto"}
      >
        <Box>
          <AddAddressForm />
          <Container maxW="container.lg" py="8">
            {address?.map((boxData) => (
              <SelectAddress
                key={boxData._id}
                data={boxData}
                user_id={userData?.data?._id}
              />
            ))}
          </Container>
        </Box>
        <Box>
          <HStack bg={"#151B39"} p={5} borderRadius={"14px"}>
            <Icon type={"EXCLAMATORYMARK"} color="#fff" size={"34px"} />
            <Box>
              <Text color={"#F6AA00"} fontSize={"16px"}>
                TAKE ACTION
              </Text>
              <Text color={"#FFFFFF"} fontSize={"14px"}>
                Please select a valid address, before proceeding.
              </Text>
            </Box>
          </HStack>
          <Box
            w={["100%", "514px"]}
            h="286px"
            borderRadius="14px"
            background="#F5F5F5"
            p={6}
          >
            <Text color="#707070" fontSize="16px" fontWeight="700">
              Payment Details
            </Text>
            <Box color="#404040" fontSize="16px" fontWeight="500" mt={"20px"}>
              <Box display="flex" justifyContent="space-between">
                <Text>MRP Total</Text>
                <Text>₹ .00</Text>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Text>Shipping/Delivery Charges</Text>
                <Text>₹ 7,500.00</Text>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                color="#52771A"
                mt="30px"
              >
                <Text>Total Payable</Text>
                <Text>₹ 7500.00</Text>
              </Box>
            </Box>

            <Flex justify="space-between" mt={"30px"}>
              <Box>
                <Text color="#8E8E8E" fontSize="10px" fontWeight="700">
                  Total Payable
                </Text>
                <Text fontWeight="700">₹ 7500.00</Text>
              </Box>
              <Link to={"/addresspage"}>
                <Box>
                  <Button
                    w="200px"
                    h="50px"
                    borderRadius="7px"
                    backgroundColor="#52771A"
                    color="#FFFFFF"
                    fontSize="16px"
                    fontWeight="700"
                  >
                    PAY
                  </Button>
                </Box>
              </Link>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default AddressPage;
