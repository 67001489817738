// A mock function to mimic making an async request for data
import axios from "axios";
export function createService(service) {
  return new Promise(async (resolve) => {
    const response =await axios.post(process.env.REACT_APP_BASE_API+"/service/addService", service);
    const data = response.data;

    resolve({ data });
  });
}


export function fetchService() {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      process.env.REACT_APP_BASE_API+"/service/getService"
    );
    const data = response.data;
    resolve({ data });
  });
}
